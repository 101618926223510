/* Question.css */
.accordion-item {
  border-bottom: 1px solid #e5e7eb;
  padding-bottom: 1rem;
  text-align: left;
}

.accordion-header {
  
  justify-content: space-between;
}

.accordion-button {
 
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.accordion-title {
  font-size: 1.125rem;
  font-weight: 600;
  text-align: left;
}

.accordion-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.plus-icon {
  color: #718096; /* gray */
}

.minus-icon {
  color: #4299e1; /* blue */
}

.accordion-content {
  margin-top: 0.5rem;
}

.answer-text {
  color: #4a5568; /* gray */
}