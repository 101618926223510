
:root {
  --c-gray-900: #000000;
  --c-gray-800: #1f1f1f;
  --c-gray-700: #2e2e2e;
  --c-gray-600: #313131;
  --c-gray-500: #969593;
  --c-gray-400: #a6a6a6;
  --c-gray-300: #bdbbb7;
  --c-gray-200: #f1f1f1;
  --c-gray-100: #ffffff;
  --c-green-500: #45ffbc;
  --c-olive-500: #e3ffa8;
  --c-white: var(--c-gray-100);
  --c-text-primary: var(--c-gray-100);
  --c-text-secondary: var(--c-gray-200);
  --c-text-tertiary: var(--c-gray-500);
}

.body {
  
 background-color: #f5f9fb;
}

.img {
  display: block;
  max-width: 100%;
}

button,
select,
input,
textarea {
  font: inherit;
}

a {
  color: inherit;
}

.responsive-wrapper {
  width: 90%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}
.error {
  color: red;
  font-size: 0.875rem; /* Optional: Adjust the font size as needed */
  margin-top: 0.25rem; /* Optional: Adds space above the error message */
}

.app {
  min-height: 80vh;
  width: 95%;
 

  padding: 2vw 4vw 6vw;
  display: flex;
  flex-direction: column;
}

.app-header {
  display: grid;
  grid-template-columns: minmax(-webkit-min-content, 175px) minmax(-webkit-max-content, 1fr) minmax(-webkit-max-content, 400px);
  grid-template-columns: minmax(min-content, 175px) minmax(max-content, 1fr) minmax(max-content, 400px);
  -moz-column-gap: 4rem;
       column-gap: 4rem;
  align-items: flex-end;
}
@media (max-width: 1200px) {
  .app-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--c-gray-600);
  }
}

@media (max-width: 1200px) {
  .app-header-navigation {
    display: none;
  }
}

.app-header-actions {
  display: flex;
  align-items: center;
}
@media (max-width: 1200px) {
  .app-header-actions {
    display: none;
  }
}

.app-header-actions-buttons {
  display: flex;
  border-left: 1px solid var(--c-gray-600);
  margin-left: 2rem;
  padding-left: 2rem;
}
.app-header-actions-buttons > * + * {
  margin-left: 1rem;
}

.app-header-mobile {
  display: none;
}
@media (max-width: 1200px) {
  .app-header-mobile {
    display: flex;
  }
}

.app-body {
  height: 100%;
  display: grid;
  grid-template-columns: minmax(-webkit-min-content, 175px) minmax(-webkit-max-content, 1fr) minmax(-webkit-min-content, 40px);
  grid-template-columns: minmax(min-content, 175px) minmax(max-content, 1fr) minmax(min-content, 400px);
  -moz-column-gap: 4rem;
       column-gap: 4rem;
  padding-top: 2.5rem;
}
@media (max-width: 1200px) {
  .app-body {
    grid-template-columns: 1fr;
  }
  .app-body > * {
    margin-bottom: 3.5rem;
  }
}

.app-body-navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .app-body-navigation {
    display: none;
  }
}

.footer {
  margin-top: auto;
}
.footer h1 {
  font-size: 1.5rem;
  line-height: 1.125;
  display: flex;
  align-items: flex-start;
}
.footer h1 small {
  font-size: 0.5em;
  margin-left: 0.25em;
}
.footer div {
  border-top: 1px solid var(--c-gray-600);
  margin-top: 1.5rem;
  padding-top: 1rem;
  font-size: 0.75rem;
  color: var(--c-text-tertiary);
}

.logo {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
  border-bottom: 1px solid var(--c-gray-600);
}
@media (max-width: 1200px) {
  .logo {
    border-bottom: 0;
  }
}

.logo-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.logo-title {
  display: flex;
  flex-direction: column;
  line-height: 1.25;
  margin-left: 0.75rem;
}
.logo-title span:first-child {
  color: var(--c-text-primary);
}
.logo-title span:last-child {
  color: var(--c-text-tertiary);
}

.navigation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--c-text-tertiary);
}
.navigation a {
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: 0.25s ease;
}
.navigation a * {
  transition: 0.25s ease;
}
.navigation a i {
  margin-right: 0.75rem;
  font-size: 1.25em;
  flex-shrink: 0;
}
.navigation a + a {
  margin-top: 1.25rem;
}
.navigation a:hover, .navigation a:focus {
  transform: translateX(4px);
  color: var(--c-text-primary);
}

.tabs {
  display: flex;
  justify-content: space-between;
  color: var(--c-text-tertiary);
  border-bottom: 1px solid var(--c-gray-600);
}
.tabs a {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-decoration: none;
  border-top: 2px solid transparent;
  display: inline-flex;
  transition: 0.25s ease;
}
.tabs a.active, .tabs a:hover, .tabs a:focus {
  color: var(--c-text-primary);
  border-color: var(--c-text-primary);
}

.user-profile {
  display: flex;
  align-items: center;
  border: 0;
  cursor: pointer;
  color: var(--c-text-tertiary);
  transition: 0.25s ease;
}
.user-profile:hover, .user-profile:focus {
  color: var(--c-text-primary);
}
.user-profile:hover span:last-child, .user-profile:focus span:last-child {
  box-shadow: 0 0 0 4px var(--c-gray-800);
}
.user-profile span:first-child {
  display: flex;
  font-size: 1.125rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--c-gray-600);
  font-weight: 300;
}
.user-profile span:last-child {
  transition: 0.25s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 1.5rem;
  flex-shrink: 0;
}

.button {
  width: 32px;
  height: 32px;
  border-radius: 20%;
  border: 0;
  float: right;
  border: 1px solid var(--c-gray-500);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.25s ease;
  flex-shrink: 0;
  padding: 20px;
  margin-left: 10px;
 
}
.icon-button.large {
  width: 42px;
  height: 42px;
  font-size: 1.25em;
}
.icon-button i {
  transition: 0.25s ease;
}
.icon-button:hover, .icon-button:focus {
  background-color: var(--c-gray-600);
  box-shadow: 0 0 0 4px var(--c-gray-800);
}

.tiles {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  row-gap: 1rem;
  margin-top: 1.25rem;
}
@media (max-width: 700px) {
  .tiles {
    grid-template-columns: repeat(1, 1fr);
  }
}

.tile {
  padding: 1rem;
  border-radius: 8px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  transition: 0.25s ease;
  background-color: #ffffff; /* White background */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow effect */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Add padding for content */

}
.tile:hover {
  transform: translateY(-5px);
}
.tile:focus-within {
  box-shadow: 0 0 0 2px var(--c-gray-800);
}
.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.table tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #ddd;
}

.button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color:  #022D36;
}
.tile a {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
}
.tile a .icon-button {
  color: inherit;
  border-color: inherit;
}
.tile a .icon-button:hover, .tile a .icon-button:focus {
  background-color: transparent;
}
.tile a .icon-button:hover i, .tile a .icon-button:focus i {
  transform: none;
}
.tile a:focus {
  box-shadow: none;
}
.tile a:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.tile-header {
  display: flex;
  align-items: center;
}
.tile-header i {
  font-size: 2.5em;
}
.tile-header h3 {
  display: flex;
  flex-direction: column;
  line-height: 1.375;
  margin-left: 0.5rem;
}
.tile-header h3 span:first-child {
  font-weight: 600;
}
.tile-header h3 span:last-child {
  font-size: 0.825em;
  font-weight: 200;
}

.service-section > h2 {
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
}

.service-section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.service-section-header > * + * {
  margin-left: 1.25rem;
}


.transfer-section {
  margin-top: 2.5rem;
}

.transfer-section-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid var(--c-gray-600);
}
.transfer-section-header h2 {
  font-size: 1.5rem;
}



.filter-options {
  margin-left: 1.25rem;
  padding-left: 1.25rem;
  border-left: 1px solid var(--c-gray-600);
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  float: right;
}
.filter-options p {
  
  font-size: 0.875rem;
}
.filter-options p + * {
  margin-left: auto;
  margin-right: 0.75rem;
}
@media (max-width: 1000px) {
  .filter-options p {
    display: none;
  }
}

.transfers {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}

.transfer {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 0.875rem;
}
@media (max-width: 1000px) {
  .transfer {
    align-items: flex-start;
    flex-direction: column;
  }
}
.transfer + * {
  margin-top: 2rem;
}

.transfer-logo {
  background-color: var(--c-gray-200);
  border-radius: 4px;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.transfer-logo img {
  width: 45%;
}

.transfer-details {
  margin-left: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}
@media (max-width: 1000px) {
  .transfer-details {
    flex-wrap: wrap;
    margin-left: 0;
    margin-top: 1rem;
  }
}
.transfer-details div {
  width: calc(100% / 3 - 1rem);
}
@media (max-width: 1000px) {
  .transfer-details div {
    width: 100%;
  }
}
.transfer-details div + div {
  margin-left: 1rem;
}
@media (max-width: 1000px) {
  .transfer-details div + div {
    margin-left: 0;
    margin-top: 1rem;
  }
}
.transfer-details dd {
  color: var(--c-text-tertiary);
  margin-top: 2px;
}

.transfer-number {
  margin-left: 2rem;
  font-size: 1.125rem;
  flex-shrink: 0;
  width: 15%;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1000px) {
  .transfer-number {
    margin-left: 0;
    margin-top: 1.25rem;
    width: 100%;
    justify-content: flex-start;
  }
}

.payment-section > h2 {
  font-size: 1.5rem;
}

.payment-section-header {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.payment-section-header p {
  color: var(--c-text-tertiary);
  font-size: 0.875rem;
}
.payment-section-header div {
  padding-left: 1rem;
  margin-left: auto;
  display: flex;
  align-items: center;
}
.payment-section-header div > * + * {
  margin-left: 0.5rem;
}
.mh {
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.pd{
  
  padding-bottom: 10px;
}
.dp{
  float:left;
  margin-left:-20rem;
  
}
.wasswa {
    margin: 0 auto;
    width: 100vh;
    background-color:  white;
    border-radius:0.375rem;
    padding: 4rem;
    margin-top: 7rem; 
    align-items: center;
    
    
  }
  .ha{
    background-color:#f3fafa;
    
  }
.card-button {
  display: flex;
  width: 50px;
  height: 34px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: transparent;
  transition: 0.25s ease;
  border-radius: 4px;
  border: 2px solid var(--c-gray-600);
  color: var(--c-text-primary);
  cursor: pointer;
}
.card-button.mastercard svg {
  max-height: 15px;
}
.card-button:focus, .card-button:hover, .card-button.active {
  color: var(--c-gray-800);
  background-color: var(--c-white);
  border-color: var(--c-white);
}

.faq {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
}
.faq p {
  color: var(--c-text-tertiary);
  font-size: 0.875rem;
}
.faq div {
  margin-top: 0.75rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-top: 1px solid var(--c-gray-600);
  border-bottom: 1px solid var(--c-gray-600);
  font-size: 0.875rem;
  display: flex;
  align-items: center;
}
.faq div label {
  padding-right: 1rem;
  margin-right: 1rem;
  border-right: 1px solid var(--c-gray-600);
}
.faq div input {
  border: 0;
  background-color: transparent;
  padding: 0.25em 0;
  width: 100%;
}

.payment-section-footer {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
}

.save-button {
  border: 1px solid currentColor;
  color: black;
  border-radius: 6px;
  padding: 0.75em 2.5em;
  background-color: transparent;
  transition: 0.25s ease;
  cursor: pointer;
}
.table-container-scrollable {
    overflow-x: auto;
    max-width: 100%;
}

.table {
    width: 100%; /* Ensure table takes up full width */
}
.break-word {
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.table-container-scrollable {
  overflow-x: auto; /* Enable horizontal scrolling */
  max-width: 100%; /* Ensure the container takes up the available width */
}
/* Media query for small devices */
@media screen and (max-width: 768px) {
    .table-container-scrollable {
        overflow-x: scroll; 
        max-width: 350px;
        /* Enable horizontal scrolling */
    }
    
    .mh {
  min-height: 20vh; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.pd{
  
  padding-bottom: 20px;
}
.dp{
 
  margin-left:-9rem;
  
}

.wasswa {
    
    width: 50vh;
    background-color:  white;
    border-radius:0.375rem;
    padding: 1rem;
    margin-top: 7rem; 
    align-items: center;
  }
}
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    z-index: 9999;
    overflow-y: auto;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 85%;
    width: auto;
    overflow-y: auto;
    max-height: 90vh;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 30px;
    color: black;
    font-weight: bold;
}

.modal-content h2 {
    margin-top: 0;
}

.modal-content form {
    display: flex;
    flex-direction: column;
}

.modal-content label {
    margin-top: 10px;
}

.modal-content input,
.modal-content textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.modal-content button {
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #022D36;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
.float{
  float: right;
}
.modal-content button:hover {
    background-color: #0056b3;
}

.tag-scrollers {
	width: 100%;
	max-width: 1200px;
	overflow: hidden;
}

/*	POSSIBLY UPDATE COLORS IN THE GRADIENT
		TO MATCH THE PROJECTS DESIGN SYSTEM
*/
.tag-scroller {
	display: grid;
	gap: 1.5rem;
	mask: linear-gradient(90deg, #0000, var(--background) 15%, var(--background) 85%, #0000);
}

.tag-list {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	/*	IF THE GAP IS CHANGED, DON'T FORGET TO CHANGE 
			THE TRANSFORM VALUE IN THE SCROLL ANIMATION
			AT THE END OF THE STYLING
	*/
	gap: 1.5rem;
}

/*	IF THE USERS PREFERS REDUCED MOTION
		PRESENT THEM WITH A HOROZONTAL LIST
		OF ALL ELEMENTS AND GIVE THEM THE
		OPTION TO MANUALLY SCROLL BY SWIPING
*/
@media (prefers-reduced-motion) {
	.tag-list {
		flex-flow: row nowrap;
		overflow: auto;
		scrollbar-width: none;
		scrollbar-color: transparent transparent;
	}
	.tag-list::-webkit-scrollbar-track {
		background: transparent;
	}
	.tag-list::-webkit-scrollbar-thumb {
		background: transparent;
	}
	.tag-list::-webkit-scrollbar {
		display: none;
		width: 0;
		height: 0;
	}
}
.tag-list li {
	font-family: system-ui;
	font-size: 1.125rem;
	line-height: 1;
	padding: 0.625rem 1.375rem;
	border: 2px solid #000;
	border-radius: 10ch;
	white-space: nowrap;
}


.tag-scroller.scrolling .tag-list {
	width: max-content;
	flex-wrap: nowrap;
	animation: horizontal-scroll var(--duration) var(--direction, normal) linear infinite;
}

.tag-scroller.scrolling .tag-list:nth-child(even) {
	--direction: reverse;
}

/* PAUSING THE ANIMATION ON HOVER */
.tag-scroller:hover .tag-list {
	animation-play-state: paused;
}

@keyframes horizontal-scroll {
	to {
		/*	0.75rem FOR HALF THE GAP
				OF THE .tag-scroller
		*/
		transform: translateX(calc(-50% - .75rem));
	}
}
.cardy {
  background-color: #fff;

  box-shadow: 2px 2px 5px #9E9E9E, -1px -1px 5px #9E9E9E;
  border-radius: 3px;
  display: grid;
  margin-top:40px;
  
}
.cardy .img-container {
  width: 230px;
 
  grid-column: 2;
  background-size: cover;
  background-position: center center;

    position: relative;
}

.overlayy {
    position: absolute;
    top: 20%;
    left: 10%;
    transform: translate(-15%, -50%);
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    padding: 10px 20px; /* Adjust padding as needed */
}
.img-text{
  color: black;
  background-color: white;
  
  margin-top: 20px;
  width: 70%;
  
}
.img-text p{
  margin-top: 2rem;
  padding: 10px;
  
}

.card-content {
  grid-column: 3 / 5;
  padding: 10px 30px;
  border-left: 1px solid #ccc;
}
.h2 {
  text-transform: uppercase;
  color: #555;
}
h1 {
  margin-bottom: 0;
}
.card-content .author {
  border-top: 1px solid #cdcdcd;
  font-weight: 700;
  margin-top: 25px;
  padding: 25px 0 10px 0;
  color: #555;
}

@media only screen and (max-width:600px) {
  .cardy {
    display: block;
    height: 740px;
  }
  .cardy .img-container {
    height: 170px;
    width: 100%;
  }
  .card-content {
    border: 0;
    border-top: 1px solid #ccc;
  }
  
  .overlayy {
    position: absolute;
    top: 20%;
    left: 7%;
    transform: translate(-15%, -50%);
}
}

.pen-title {
  color: #FFFFFF;
  margin-bottom: 0;
  text-align: center;
  text-shadow: 1px 1px 0 #000;
}

.pen-description {
  color: #FFFFFF;
  margin-bottom: 3rem;
  text-align: center;
  text-shadow: 1px 1px 0 #000;
}
.pen-description a {
  color: #FFFFFF;
  text-decoration: underline;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

img {
  height: auto;
  max-width: 100%;
}

.wrap {
  max-width: 85.375rem;
  
}

.image-as-background {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.screen-reader-text {
  height: auto;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
}
.screen-reader-text:hover, .screen-reader-text:active, .screen-reader-text:focus {
  height: auto;
  width: auto;
  background-color: #000000;
  border-radius: 2px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #FFFFFF;
  display: block;
  font-size: 0.8rem;
  font-weight: 700;
  left: 0.3125rem;
  line-height: normal;
  padding: 0.9375rem 1.4375rem 0.875rem;
  text-decoration: none;
  top: 0.3125rem;
  z-index: 100000;
}

.logozo {
  padding: 10px;
 
}

.logo-slider {
  
   
    background-color: #fff;
}

.logos-slide {
    display: inline-block;
    
}

.logos-slide img {
    width: 60px;
    height: 43px;
    float: left;
    
    background-color: none;
}



.listBtnDropdown {
  display: flex;
  flex-direction: row;
  align-items: end;
  max-height: 5px;
  margin:0 auto;
}

.btnDropdown {
  flex: 1 1 auto;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.btnNav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  height: 30px;
  width: 100%;
  padding: 0 16px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  color: white!important;
}

.btnNav .chevron {
  font-size: 8px;
  opacity: 0.2;
  transition: all 340ms;
  color: black!important;
}

.dropdown {
  display: grid;
  width: calc(100% - 8px);
  grid-template-rows: 0fr;
  background: #f6f6f6;
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  border-top: 1px solid #e4e4e4;
  border-radius: 16px 16px 0 0;
  opacity: 0;
  transition: all 340ms;
}

.dropdown > .dropdown-contenty {
  overflow: hidden;
  opacity: 0;
  transition: all 340ms;
}

.btnDropdown.first .btnNav { 
  border-radius: 8px 0 0 8px; 
  background: #022d36;
}

.btnDropdown.two .btnNav { 
  background: #ff9333;
}

.btnDropdown.three .btnNav { 
  border-radius: 0 8px 8px 0; 
  background: #ff3633;
}

.btnDropdown:hover .dropdown {
  grid-template-rows: 1fr;
  opacity: 1;
}

.btnDropdown:hover .chevron {
  transform: rotate(180deg);
  transition: all 340ms;
  opacity: 1;
}

.btnDropdown:hover .dropdown .dropdown-contenty {
  opacity: 1;
  transition: all 1s;
}

.dropdown-contenty ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.dropdown-contenty li {
  padding: 6px 8px;
  font-size: 13px;
  border-bottom: 1px solid #d7d7d7;
}
.but{
  
  padding: 20px;
}
.cmo{
  float: right;
  font-size: 20px;
  color: green;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

*{
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
}

:root{
    --pinkish-red: #C35A74;
    --medium-blue: #ff3633;
    --greenish-blue: #022d36;
    --bright-orange: #ff9333;
    --white-smoke: #F5F5F4;
    --white: #FFF;
    --dark-gray: #7D7C7C;
    --black: #000;
}



.content-jvw{
    display: flex;
   
    justify-content: center;
    align-content: center;
   
}

.box-jvw{
    display: flex;
    flex-direction: column;
    height: 486px;
    width: 250px;
    margin-left: 20px;
    border-radius: 20px;
    background: var(--white);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 20%);
   
}

.title-jvw{
    width: 100%;
    padding: 10px 0;
    font-size: 1.2em;
    font-weight: lighter;
    text-align: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    color: var(--white-smoke);
}

.basic .title-jvw{
    background: var(--bright-orange);
}

.standard .title-jvw{
    background: var(--medium-blue);
}

.business .title-jvw{
    background: var(--greenish-blue);
}

.view{
    display: block;
    width: 100%;
    padding: 30px 0 20px;

    background: var(--white-smoke);
}

.icon-jvw{
    display: flex;
    justify-content: center;
}

.icon-jvw img{
    width: 40px;
}

.cost{
    display: flex;
    justify-content:center;
    flex-direction: row;
    margin-top: 10px;
    font-size: 10px;
}

.amount{
    font-size: 2.8em;
    font-weight: bolder;
}

.detail{
    margin: auto 0 auto 5px;
    width: 70px;
    font-size: 0.7em;
    font-weight: bold;
    line-height: 15px;
    color: #7D7C7C;
}

.description{
    margin: 10px auto;
    font-size: 0.8em;
    color: #7D7C7C;
}

ul{
    list-style: none;
}

li{
    margin-top: 10px;
}

.description li::before{
    content: "";
    background-image: url("https://i.postimg.cc/ht7g996V/check.png");
    background-position: center;
    background-size: cover;
    opacity: 0.5;

    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
}

.button-jv{
    margin: 0 auto 30px;
}

.button-jv button{
    height: 40px;
    width: 150px;
    font-size: 0.7em;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #7D7C7C;
    border: 2px solid var(--dark-gray);
    border-radius: 50px;

    background: transparent;
}

.txt{
  text-align: center;
  margin-top: 10px;
}
.button-jv button:hover{
    color: var(--white-smoke);
    transition: 0.5s;
    border: none;

    background: var(--bright-orange);
}

/* Responsiveness:Start */
@media screen and (max-width:970px) {
    .content-jvw{
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 50px auto;
    }
    .standard, .business{
        margin-top: 25px;
    }
}
.fas{
  
  padding: 20px;
  
}

