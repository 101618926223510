@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900;1000&family=Roboto:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);

@tailwind base;
@tailwind components;
@tailwind utilities;
 html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    box-sizing: border-box;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  a {
    text-decoration: none!important;
    color: #022D36;
  }
  a:hover {
    color: #D0312D;
  }

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important; /* Change the color to match your background */
}
  body {
    background: #fff;
    color: #0B093B;
    font-family: 'Khula', sans-serif;
    overflow-x: hidden;
    font-size: 16px;
    font-weight: 400;
  }
  .container {
    width: 100%;
    max-width: 1220px;
    margin: 0 auto;
    padding: 0px 30px;
  }
  .padding{
   padding: 20px; 

    
  }
  @media only screen and (max-width: 859px) {
    .container {
      padding: 0 15px;
    }
  }
  .regular {
    font-weight: 400!important;
  }
  .semiBold {
    font-weight: 600!important;
  }
  .extraBold {
    font-weight: 700!important;
  }


.overlayz{
 
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9) !important; /* Overlay background color with transparency */
  color: white;
  text-align: center;
  padding: 5px;
  box-sizing: border-box;
}
  /* HELLPERS */
  .textCenter {
    text-align: center
  }
  .align {
    
    text-align: justify;
  }
  .animate {
    transition: 0.3s ease;
  }
  .pointer {
    cursor: pointer;
  }
  .relative {
    position: relative;
  }
  .textCenter {
    text-align: center;
  }
  .textLeft {
    text-align: left;
  }
  .textRight {
    text-align: right;
  }
  .x100 {
    width: 100%;
  }
  .red{
    color:#D0312D ;
  }
  .blue{
    color:blue;
  }
  .active {
    border-bottom: 2px solid #D0312D;
    color: #D0312D;
  }
  .tag {
    padding: 5px 10px;
    margin: 10px 0;
  }
  .shadow {
    box-shadow: 0 0 11px rgba(237, 232, 237, 0.5)  ; 
    -webkit-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5)  ; 
    -moz-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5)  ; 
  }
  /* FLEXBOX */
  .flex {
    display: flex;
  }
  .flexSpaceCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flexNullCenter {
    display: flex;
    align-items: center;
    
  }
  .flexColumn {
    flex-direction: column;
  }
  .flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .viannie{
   text-align: justify;
  }
  .flexSpaceNull {
    display: flex;
    justify-content: space-between;
  }
.leftor{
  
  align-items: left;
  text-align: left;
  list-style-type: disc;
  
}
.leftor li{
  
  padding: 5px;
}
  /* FONT SIZES */
  .font11 {
    font-size: 0.688rem;
  }
  .font12 {
    font-size: 0.75rem;
  }
  .font13 {
    font-size: 0.813rem;
  }
  .font14 {
    font-size: 0.875rem;
  }
  .font15 {
    font-size: 0.938rem;
  }
  .font18 {
    font-size: 1.125rem;
  }
  .font20 {
    font-size: 1.25rem;
  }
  .font25 {
    font-size: 1.563rem;
  }
  .font30 {
    font-size: 1.875rem;
  }
  .font40 {
    font-size: 2.5rem;
  }
  .font60 {
    font-size: 3.75rem;
    line-height: 4.5rem;
  }
  @media only screen and (max-width: 859px) {
    .font11 {
      font-size: 0.688rem;
    }
    .font12 {
      font-size: 0.688rem;
    }
    .font13 {
      font-size: 0.75rem;
    }
    .font14 {
      font-size: 0.813rem;
    }
    .font15 {
      font-size: 0.813rem;
    }
    .font18 {
      font-size: 0.938rem;
    }
    .font20 {
      font-size: 1.125rem;
    }
    .font25 {
      font-size: 1.25rem;
    }
    .font30 {
      font-size: 1.5rem;
    }
    .font40 {
      font-size: 1.875rem;
    }
    .font60 {
      font-size: 2.8rem;
    }
    
  }
  
  .margin {
  display: none; /* Hide by default */
}
.Button1{
  display:none;
 
}
@media only screen and (max-width: 768px) {
  .Button1 {
    display: none; /* hide on mobile */
  }
}

@media only screen and (min-width: 769px) {
  .Button1 {
    display: none; /* hide on tablets */
  }
}
/* Media query for small devices (up to 768px width) */
@media only screen and (max-width: 768px) {
  .margin {
    display: flex; /* Show on small devices */
    margin-top: 10px;
    height: 45%;
    position: absolute;
  }
}
  /* BORDER RADIUS */

  .radius8 {
    border-radius: 1rem; 
  }
  .radius6 {
    border-radius: 0.6rem;
  }
  /* COLORS */
  .darkColor {
    color: #022D36;
  }
  .cc{
        color: #D0312D;
  }
  .purpleColor {
    color: #D0312D;
  }
  .orangeColor {
    color: #F2B300;
  }
  .lightColor {
    color: #F5F5F5;
  }
  .coralColor {
    color: #C4FFFF;
  }
  .greenColor {
    color: #49CB86;
  }
  .greyColor {
    color: #707070;
  }
  .whiteColor {
    color: #fff;
  }
  /* BACKGROUNDS */
  .darkBg {
    background-color: #022D36;
  }
  
  .liBg{
    
    background-color: #ececec;
  }
  .purpleBg {
    background-color: #D0312D;
  }
  .orangeBg {
    background-color: #F2B300;
  }
  .lightBg {
    background-color: #F5F5F5;
  }
  .hardBg{
    
    background-color: #022D36;
 
  }
  .coralBg {
    background-color: #C4FFFF;
  }
  .greenBg {
    background-color: #49CB86;
  }
  .greyBg {
    background-color: #707070;
  }
  .whiteBg {
    background-color: #fff;
  }



/******* Search Bar css****/

/* SearchBar.css */
.search-input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  width: 150px;
  box-sizing: border-box;
}


.search-container {
  position: relative; /* Make the container a positioning context */
}

.search-suggestions {
  position: absolute;
  top: 100%; /* Position the suggestions below the search input */
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: calc(100% - 2px); /* Adjust width to match the container's width */
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.suggestion-item {
  padding: 8px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f5f5f5;
}
/* SearchBar.css */
.search-container {
  display: flex;
  align-items: center; /* Align items vertically */
}

.search-input {
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px; /* Adjust border-radius */
  padding: 8px;

  box-sizing: border-box;
}

.search-button {
  background-color: #D0312D;
  color: #fff;
  border: none;
  border-radius: 0 4px 4px 0;
  padding: 8px 16px;
  cursor: pointer;
  
}
/* Community.css */

.first-text {
  font-weight: light;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}


.text-steps-container {
  display: flex;
  flex-direction: column;
  grid-gap: 6px;
  gap: 6px;
}

.second-text {
  font-size: 20px;
  font-weight: medium;
  color: rgba(0, 0, 0, 0.8);
  display: inline-block;
}

.steps-list {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}

.step {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}
.floater{

  float: left;
 margin-left: -30px;
}

.step-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 15px;
  color: #fff;
}

.step-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spin {
  animation: spin 10s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bo{
  border: 1px solid #D0312D;
  background-color: #D0312D;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  border-radius: 20px;
}
  .red{
    
    color: red;
  }
  .green{
    
    color: green ;
    
  }
  

.card {
    width: 288px;
    height: auto;
    background-color: white;
    border-radius: 1rem;
    
    box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-bottom: 10px; /* Adjust as needed */
    margin-left: auto; /* Center horizontally */
    margin-right: auto; /* Center horizontally */
}

figure {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    position: relative;
}

figure img {
    width: 100%;
    height: 80%;
    object-fit: cover;
}

figure img:hover {
    transition: all 0.6s ease-in-out;
    transform: scale(1.1);
}

.icons svg {
    fill: white;
    
}


.btnAddNone{
    display: none;
}

.btnAdd span {
    color: white;
}

.btnAdd:hover {
    background-color: gray;
    
}



.descriptionCard {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
  
}

.informationCard1 {
    align-items: center;
  
    border-bottom: 1px solid #D3D3D3;
}


.shortInformation {
    padding-right: 30px;
}

.shortInformation h3 {
    font-size: 16px;
    font-weight: 700;

}

.shortInformation p {
    font-size: 12px;
    color: #A9A9A9;
}
.state{
   color:green;
  padding: 10px;
  
}
.btnComprar {
    height: 32px;
    font-size: 10px;
    background-color: #022D36;
    border-radius: 100px;
    border: none;
}

.btnComprar:hover {
    cursor: pointer;
    background-color: #022D36;
}

.btnComprar span {
    color: white;
    margin-left: 26px;
    margin-right: 26px;
    font-weight: 500;
}

.priceDescription {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    grid-gap: 5px;
    gap: 5px;
}
.marketcount{
    position: relative;
    padding-right: 5px;
}


.priceDescription p {
    font-size: 16px;
    font-weight: 600;
    color: #001547;
}
.svg {

  height: 30px;
  width: 30px;
}
.location-status-container {
  display: flex;
  align-items: center;
}

.location-container{
  display: flex;
  
 
}

.status-container img {
  
  width: 20px;
  height: 20px;
}


.showcase {
 
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.travel-card {
  position: relative;
  display: inline-flex;
  flex-flow: column nowrap;

  background-color: white;
  box-shadow: 0px 0px 100px -10px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  width: 300px;
}
.travel-card:hover > .button-favorite {
  display: inline-flex;
}
.travel-card{
  
}
.travel-card .image {
  width: 100%;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  overflow: hidden;
  height: 157px;
}

 .image  img {
  width: 100%;

}
.travel-card > .content {
  color: #545454;
  padding: 1em 1.5em;
}
.travel-card > .content > :nth-child(1n+2) {
  margin-top: 1em;
}
.category {
  font-size: 1em;
  color: #bababa;
  position: absolute;
  display: inline-block;
  white-space: nowrap;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
}


.travel-card > .content > .recommendation {
  display: flex;
  align-items: center;
}
.travel-card > .content > .recommendation > .score {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #D0312D;
font-size:10px;
  color: white;
  padding: 5px 5px;

}
.score {
  padding-top: 1px;
  padding-left: 4px;
}

.travel-card > .content > .recommendation > .comment {
  color: #bababa;
  margin-left: 1.5em;
}
.travel-card > .content > .price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.discount-info {
  color: #4fc3f7;
}
.original-price {
  font-size: 1em;
  
}
.location-image {
  vertical-align: middle;
  margin-right: 5px;
}

.travel-card > .button-favorite {
  
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
}




:root {
  --v-50: #d8e6ea;
  --v-100: #9cbecb;
  --v-200: #6f9dac;
  --v-300: #42748e;
  --v-400: #1a4c6f;
  --v-700: #022D36; 
}

.breadcrumbs {
  width: 100%;
  list-style: none;
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  
  padding: 0.2rem;
  border-radius: 0.5rem;

  font-size: 10px;
}

.breadcrumbs-item {
  padding: 0.2rem;
  text-align: center;
  white-space: nowrap;
  background: var(--v-100);
  color: var(--v-700);
  text-decoration: none;
  text-transform: uppercase;
 width:100%;
  position: relative;
}

.breadcrumbs-item::before,
.breadcrumbs-item::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 0.5rem;
  top: 0;
  left: 0;
  -webkit-clip-path: polygon(0 0, 100% 50%, 0 100%);
          clip-path: polygon(0 0, 100% 50%, 0 100%);
}

.breadcrumbs-item::before {
  background: var(--v-50)
}

.breadcrumbs-item::after {
  left: 100%;
  background: var(--v-100);
}

.breadcrumbs-item:first-child {
  padding: 0.2rem;
  border-radius: 0.25rem 0 0 0.25rem;
}

.breadcrumbs-item:first-child::before {
  display: none;
}

.breadcrumbs-item:last-child {
  border-radius: 0 0.25rem 0.25rem 0;
}

.breadcrumbs-item:last-child::after {
  display: none;
}

.breadcrumbs-item:hover,
.breadcrumbs-item:hover::after {
  
  color: var(--v-100);
}

@media only screen and (max-width: 768px) {
.question {
  margin-top:-100px;
}
.pricing {
  
  margin-top:-280px;
}
.about {
  
  margin-top: -180px;
}
}

/********* Login / signup ***********/

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
 
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  
}

.spinner {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  text-align: center;
  line-height: 40px; /* Adjust according to the height of the spinner */
  color: #3498db; /* Spinner color */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.auth {
  width: 40%;
  background-color: #fcf2f2;
  padding: 20px;
  border-radius: 8px;
  
}

.button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.button-container button {
    padding: 10px 20px;
    background-color: #022D36;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.button-container button:disabled {
    background-color: #A0A0A0;
    color: #FFF;
    cursor: not-allowed;
}
@media only screen and (max-width: 768px) {
  
  .auth{
  width: 90%;
  }
}
.boxz {
    
    border: 2px solid #000000; 
    padding: 20px;
    margin-bottom: 20px;
}



.body {
  background-color: #fbe3e1;
}

form p {
  font-size: 120%;
}

.ctrlqHeaderMast {
  height: 278px;
  background: #009688;
}

.ctrlqFormContent {
  color: rgba(0,0,0,.87);
  padding: 20px 35px
}

.ctrlqFormContentWrapper {
  display: flex;
  box-orient: vertical;
  flex-direction: column
}

.ctrlqAccent {
  background-color: #a7ffeb;
  height: 8px;
}
.ctrlqCenteredContent {
  margin: auto;
  width: 600px; 
}
.ctrlqFormCard {
  background-color: #fff;
  margin-bottom: 48px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.37);
  word-wrap: break-word
}

.ctrlqFormCard:first-of-type {
  margin-top: -100px
}

.ctrlqHeaderTitle {
  box-sizing: border-box;
  font-size: 34px;
  line-height: 135%;
  max-width: 100%;
  min-width: 0%;
  margin-bottom: 22px
}

@media (max-width: 660px) {
  .ctrlqHeaderMast {
    height: 122px;
  }
  .ctrlqFormCard:first-of-type {
    margin-top: -50px;
  }

  .ctrlqCenteredContent {
    width: 90%;
  }
}

div.error {
  position: relative;
  top: -1rem;
  left: 0rem;
  font-size: 0.8rem;
  color: #FF4081;
  transform: translateY(0%);
}

/* custom.css */
/* Remove bottom border on label when input is focused */
.input-field input[type="text"]:focus + label,
.input-field input[type="text"]:focus + label::after,
.input-field input[type="text"].valid + label,
.input-field input[type="text"].valid + label::after,
.input-field input[type="text"].invalid + label,
.input-field input[type="text"].invalid + label::after {
  text-decoration: none;
  border-bottom: none !important;
  box-shadow: none !important;
}
.feature-item {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 20px;
  padding: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;

  /* Responsive adjustments */
  width: 100%; /* Default to full width */
}

.feature-item:hover {
  transform: translateY(-5px);
}

.logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.feature-logo {
  max-width: 100%;
  height: auto;
}

.content {
  text-align: center;
}

.social-links {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.social-icon {
  width: 30px;
  height: 30px;
  fill: currentColor;
  transition: transform 0.2s ease-in-out;
}

.social-icon:hover {
  transform: scale(1.2);
}



.modal-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.container {
    max-width: 1320px;
    padding: 0 2rem;
    margin: 0 auto;
    height: 100%;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 3%;
    gap: 3%;
    margin: 5% 0;
}

.card-team {
    overflow: hidden;
    padding: 20px;
    background: linear-gradient(171.38deg, #F9FBFC 3.36%, #E2EAF5 95.69%);
    box-shadow: 0px 0px 20px rgba(227, 235, 246, 0.3);
    border-radius: 30px;
    text-align: center;
}
.card-team:hover {
  transform: translateY(-5px);
}

.card-team_img img {
  max-width: 100%;
  height: auto;
}

.card-team_body {
  text-align: center;
}

.designation {
  color: gray;
}

/* Center the modal on the page */
.mod {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  margin-top: 30px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5)!important;
  max-width: 80%!important;
  max-height: 70%!important;
  width: 500px!important;
  z-index: 1000;
  height: 90%!important;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mod-l {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 999; /* Make sure it is below the modal but above other content */
}
.mod-content {
  position: relative;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}

.close-btn {
  cursor: pointer;
  padding:2px;
  background-color: #eee;
  color: black;
  border: none;
  border-radius: 5px;
  float: left;
}

.close-btn:hover {
  background-color: #0056b3;
}

.paragraph {
  margin-bottom: 1rem;
}
.card-team_img img {
    width: 100%;
    height: auto;
    border-radius: 20px;
}

.card-team_body {
    padding: 26px 10px 36px 10px;
}

.card-team_title {
    font-weight: 800;
    text-transform: capitalize;
    color: #25262C;
    font-size: 20px!important!;
    padding-bottom: 15px;
}

.designation {
    font-weight: 700;
    font-size: 22px;
    color: #959EAF;
}

.call_btn {
    margin: 140px 0 40px;
    position: relative;
    display: inline-block;
    z-index: 99;
}




@media only screen and (max-width: 1024px) {

    .grid {
        grid-template-columns: 1fr;
        grid-gap: 2%;
        gap: 2%;
    }
}
.mb-2{
  
  color:white;
  padding: 10px;
  
}

@media only screen and (max-width: 425px) {
    .container {
        padding: 0 1rem;
    }

    .card-team {
        padding: 12px;
    }

    .card-team_title {
        font-size: 20px;
    }

    .designation {
        font-size: 18px;
    }

    .call_btn {
        margin: 100px 0 25px;
    }
}
* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}



.body {
  

  justify-content: center;
  align-items: center;
  
    background: linear-gradient(180deg, #e0e9fd 0%, #e9ecf1 90%);
  
  
}

:root {
  --dark-font: #0f0f10;
  --light-font: #79798c;
}

a {
  text-decoration: none;
  cursor: pointer;
}
.pd{
  
  padding: 15px;
  
}
.app-container-jv {
   margin: 0 auto;
  position: relative;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  max-width: 1200px;
  max-height: 100vh;
 margin-top: 80px;
  box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.4);
  display: flex;
 overflow: auto;
}

.left-area {
  padding: 32px;
  flex-basis: 1 0 132px;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 300ms cubic-bezier(0.19, 1, 0.56, 1);
  position: relative;

}
.left-area.show {
  transform: translateX(0);
  opacity: 1;
}

.app-name {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--dark-font);
  margin-bottom: 32px;
}

.item-link {
  color: var(--light-font);
  margin-bottom: 32px;
  transition: 0.2s;
}
.item-link.active {
  color: var(--dark-font);
}

.btn-logout {
  border: none;
  background-color: transparent;
  color: var(--light-font);
  margin-top: auto;
  cursor: pointer;
  transition: 0.2s;
}
.btn-logout:hover {
  color: var(--dark-font);
}

.main-area {
  flex: 1 1;
  height: auto;
  overflow-y: auto;
  background: linear-gradient(97deg, #f2f7fd 0%, #f0f4fd 90%);
  border-radius: 0 10px 10px 0;
  padding-bottom: 24px;
  position: relative;
}

.main-area-header {
  padding: 24px 40px;
  background: linear-gradient(97deg, #f2f7fd 0%, #f0f4fd 90%);
}

.search-wrapper {
  border-radius: 4px;
  background-color: #fff;
  padding-right: 12px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--light-font);
  box-shadow: 0 2px 6px 0 rgba(136, 148, 171, 0.2), 0 24px 20px -24px rgba(71, 82, 107, 0.1);
  overflow: hidden;
}



.right-area {
  flex-basis: 300px;
  flex-grow: 0;
  
  transition: all 300ms cubic-bezier(0.19, 1, 0.56, 1);
}
.right-area.show {
  transform: translateX(0);
  width: 100%;
  opacity: 1;
}

.content-section {
  display: block;
  margin-top: 32px;
 
  padding: 0 40px;
}

.section-header {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
}
.section-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.section-header-link {
  display: block;
  font-size: 12px;
  line-height: 16px;
  color: #8683d6;
}

.access-links {
  display: flex;

  flex-wrap: wrap;
 
}

.access-icon {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  padding: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.access-icon svg {
  width: 36px;
  height: 36px;
}

.access-link-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 8px;
  width: 100px;
}

.access-text {
  color: var(--light-font);
  font-size: 12px;
  line-height: 24px;
}

.content-section-line,
.content-part-line {
  display: flex;
  justify-content: space-between;
}

.content-part-line {
  height: 100%;
}

.content-section-line {
  margin: 0 -8px;
}

.section-part {
  flex-basis: 49%;
}

.image-wrapper {
  
  overflow: hidden;
  width: 100%;
  display:block;
  position: relative;
  flex-basis: 48%;
  display: flex;
}
.image-wrapper img {
  width: 100%;
  height: 100%;
 object-fit: cover;
  transition: 0.2s ease-in;
}
.logox-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cmo {
  margin-left: auto;
}

.logox-section {
  display: flex;
  align-items: center;
}
.logox {
    width: 70px; /* Adjust width as needed */
    height: auto; /* Maintain aspect ratio */
}
.image-wrapper:hover img {
  transform: scale(1.125);
}

.image-overlay {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}
.imgzi {
  height: auto !important; /* Reset height to auto */
  width: 100%; /* Set width to 100% */
  max-height: 200px; /* Limit maximum height */
}

.video-info-text {
  width: calc(100% - 40px);
}
.video-info-text p {
  margin: 0;
}

.project-name, .video-subtext {
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.project-name.medium, .video-subtext.medium {
  font-size: 14px;
  line-height: 24px;
}
.project-name.tiny, .video-subtext.tiny {
  font-size: 12px;
  line-height: 16px;
}

.video-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.video-subtext {
  opacity: 0.8;
}

.files-table {
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(136, 148, 171, 0.2), 0 24px 20px -24px rgba(71, 82, 107, 0.1);
  border-radius: 12px;
  padding: 12px;
  display: table;
  table-layout: auto;
  width: 100%;
}
.files-table-header {
  display: table-header-group;
}
.files-table-row {
  display: table-row-group;
}

.table-cell {
  display: table-cell;
  font-size: 12px;
  line-height: 16px;
  color: #000;
  padding: 8px;
}

.column-header {
  font-size: 12px;
  line-height: 16px;
  color: #888da9;
}

.name-cell {
  width: 40%;
  word-break: break-all;
}
.name-cell.pdf:before {
  content: "PDF";
  background-color: #e2e9f8;
  color: #5a8ff7;
}
.name-cell.jpg:before {
  content: "JPG";
  background-color: #e4e2f1;
  color: #302d7d;
}
.name-cell:before {
  border-radius: 4px;
  font-size: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
}

.size-cell {
  width: 20%;
}

.more-action {
  border: none;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' stroke='%23888da9' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' class='feather feather-more-horizontal' viewBox='0 0 24 24'%3E%3Cdefs/%3E%3Ccircle cx='12' cy='12' r='1'/%3E%3Ccircle cx='19' cy='12' r='1'/%3E%3Ccircle cx='5' cy='12' r='1'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 24px;
  height: 16px;
  outline: none;
  cursor: pointer;
}

.fixed.main-area-header {
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
  top: 0;
  width: 100%;
  padding: 12px 40px;
  transition: 0.2s;
  animation: sticky 0.5s forwards;
}

@keyframes sticky {
  0% {
    transform: translatey(-88px);
  }
  100% {
    transform: translatey(0px);
  }
}
.video-time {
  position: absolute;
  z-index: 1;
  border-radius: 10px;
  padding: 4px 12px;
  background-color: rgba(139, 156, 163, 0.5);
  font-size: 10px;
  right: 12px;
  top: 12px;
  color: #fff;
}

.btn-play {
  border-radius: 50%;
  background-color: #fff;
  border: none;
  box-shadow: 0 2px 6px 0 rgba(136, 148, 171, 0.2), 0 24px 20px -24px rgba(71, 82, 107, 0.1);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='%2322244a' stroke='%2322244a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-play'%3E%3Cpolygon points='5 3 19 12 5 21 5 3'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}

.right-area {
  padding: 24px;
  overflow: auto;
}

.right-area-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}
.right-area-header-wrapper .more-action {
  width: 24px;
  height: 24px;
}

.download-item-line {
  padding: 12px 0;
}

.line-header {
  font-size: 12px;
  line-height: 16px;
  color: #888da9;
}

.download-area {
  background-color: #eceffb;
  border-radius: 12px;
  padding: 8px;
  display: flex;
  align-items: center;
  margin-top: 12px;
  cursor: pointer;
}

.download-item-texts {
  padding: 0 12px;
}
.download-item-texts p {
  line-height: 16px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}

.download-text-header {
  font-size: 12px;
}

.download-text-info {
  color: #888da9;
  font-size: 10px;
}
.download-text-info span {
  margin-left: 8px;
}

.download-item-icon {
  width: 32px;
}

.download-icon {
  width: 24px;
  fill: #4bc3a7;
}

.progress-bar {
  height: 4px;
  width: 100%;
  overflow: hidden;
  border-radius: 2px;
  background-color: #dadff3;
  margin: 6px 0;
}

.progress {
  height: 100%;
  width: 40%;
  background-color: #4bc0dd;
  display: block;
}

.received-item-line {
  height: 150px;
  width: 100%;
 
  padding-top: 12px;
  display: flex;
  padding-left: 4px;
}

.progress-line {
  height: 100%;
  width: 2px;
  background-color: #22244a;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
}
.progress-line:before, .progress-line:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #22244a;
  transform: translateX(-50%);
  left: 50%;
}
.progress-line:after {
  bottom: 0;
}

.time {
  margin-left: 12px;
  font-size: 10px;
  color: #888da9;
}

.received-items-content {
  padding: 24px 10px;
}

.received-files {
 
  height: 70%;
  font-size: 20px;
}
.received-files img {
 
 width: 70px;
 padding: 3px;
}
.received-files .image-wrapper {
  margin-left: 6px;
  display: flex;
  
}

.received-files-info {
  font-size: 12px;
  margin-left: 12px;
  margin-top: 15px;
  
  
}
.received-files-info span {
  color: #7a8dc5;
}

.btn-show-left-area,
.btn-show-right-area {
  position: absolute;
  top: 24px;
  width: 32px;
  height: 40px;
  border-radius: 4px;
  background-color: #fff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  display: none;
}

.btn-show-left-area {
  left: 0;
  border-radius: 0 4px 4px 0;
}

.btn-show-right-area {
  right: 0;
  border-radius: 4px 0 0 4px;
}

.btn-close-left,
.btn-close-right {
  border: none;
  background-color: transparent;
  position: absolute;
  top: 4px;
  right: 4px;
  color: var(--light-font);
  outline: none;
  cursor: pointer;
  display: none;
}

.show .btn-close-left,
.show .btn-close-right {
  display: block;
}

@media screen and (min-width: 850px) and (max-width: 1042px) {
  .access-icon {
    padding: 16px;
  }

  .access-icon svg {
    width: 20px;
    height: 20px;
  }
}
@media screen and (max-width: 900px) {
  .right-area {
    transform: translateX(100%);
    position: absolute;
    opacity: 0;
    z-index: 2;
    height: 100%;
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.4);
  }

  .btn-show-right-area {
    display: flex;
  }

  .access-icon svg {
    width: 36px;
    height: 36px;
  }
}
@media screen and (max-width: 768px) {
  .left-area {
    transform: translateX(-100%);
    opacity: 0;
    position: absolute;
    z-index: 2;
    height: 100%;
    background-color: #fff;
    background-image: none;
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.4);
  }

  .btn-show-left-area {
    display: flex;
  }

  .content-section-line,
.content-part-line {
    flex-direction: column;
  }

  .image-wrapper {
    margin: 10px 0;
  }

  .project-name.tiny, .video-subtext.tiny {
    font-size: 16px;
    line-height: 24px;
  }

  .access-link-wrapper {
    width: 33.3%;
  }
}
@media screen and (max-width: 520px) {
  body {
    padding: 0;
  }

  .app-container-jv {
    border-radius: 0;
  }

  .content-section {
    padding: 0 20px;
  }

  .content-section-line {
    margin: 0;
  }
}
.video-container {
  position: relative;
  width: 100%; 
  margin-top: 12rem;
}

.thumbnail {
  width: 100%;
  height: auto;
  cursor: pointer;
}



.overlay-content {
  text-align: center;
  color: #fff;
}

.video-container:hover .overlay {
  display: flex;
}
.play-container {
  position: relative;
  display: inline-block;
 
  /* Ensure inline-block to contain the absolute positioning */
}
.play-icon {
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  position: absolute;
  margin-left:-25px;
  margin-top:30px;
}

.play-animation {
  position: relative;
  transform: translate(-50%, -50%);
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Adjust size as needed */
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3); /* Adjust color and opacity */
  animation: pulse 2s infinite ease-in-out; /* Adjust animation duration and timing */
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
 .videoz{
   width:470px;
   margin: 0 auto;
 }
 
  
@media only screen and (max-width: 768px) {
  .video {
    display: flex; /* Show on small devices */
    margin-top: 10px;
    height: 45%;
    position: absolute;
  }
 .videoz {
width: 380px;
margin: 0 auto;
}
}

.ser{
  padding: 60px 0px;
}
.ser-box{
  display: inline-block;
  padding: 20px;
  margin-bottom: 20px;
  border-bottom: 1px dashed #dfe0e1;
  padding-top: 40px;
}
.ser-box:hover{
  background: #f2f2f2;
  cursor: pointer;
  
   transition: all 1s;
  -moz-transition: all 1s; /* Firefox 4 */
  -webkit-transition: all 1s; /* Safari and Chrome */
  -o-transition: all 1s; /* Opera */
}
.ser-box:hover i{
  background: #4A89DC;
  border-radius: 100px;
  
  transition: all 1s;
  -moz-transition: all 1s; /* Firefox 4 */
  -webkit-transition: all 1s; /* Safari and Chrome */
  -o-transition: all 1s; /* Opera */
}
.icon{
  padding: 0px;
  margin-top: 10px;
  float: left;
  margin-right: 20px;
  padding-bottom:10px;
  width: 150px;
  
}
.icon i{
  width: 100px;
  height: 100px;
  background: #5D9CEC;
  color: #ffffff;
  line-height: 70px;
  text-align: center;
  font-size: 44px;
}
.ser-content{

}
.ser-content h4{
  font-size: 35px;
  font-weight: 900;
  height: 35px;
}
.para{
  font-size: 18px;
  text-align: justify;
  padding: 15px;
  
}
.center{
  text-align: center ;
  algn-items: center;
  padding-top: 10px;
  margin-right: 10px;
  
}
.h2 {
  text-align: center;
  text-transform: none;
  margin-top: 20px;
}
.row h2 {
  text-align: center;
}

/* Media query for small devices */
@media (max-width: 600px) {
  .ser-content h4{
  font-size: 25px;
  font-weight: 900;
  height: 35px;
}

}
.button-r {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 15px;
}

.button-wrapper a {
  text-decoration: none;
}


.section {
 
  width: 100%;
  display: grid;
  place-items: center;
}
.row {
  display: flex;
  flex-wrap: wrap;
}
.column {
  width: 100%;
  padding: 0 1em 1em 1em;
  text-align: center;
}
.cardz {
  width: 100%;
  height: 100%;
  padding: 2em 1.5em;
  background: linear-gradient(#ffffff 50%, #022D36 50%);
  background-size: 100% 200%;
  background-position: 0 2.5%;
  border-radius: 5px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: 0.5s;
}
.section h3 {
  font-size: 20px;
  font-weight: 600;
  color: #1f194c;
  margin: 1em 0;
}
.section p {
  color: #575a7b;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0.03em;
}
.icon-wrapper {
  background-color: #022D36;
  position: relative;
  margin: auto;
  font-size: 30px;
  height: 2.5em;
  width: 2.5em;
  color: #ffffff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: 0.5s;
}
.cardz:hover {
  background-position: 0 100%;
}
.cardz:hover .icon-wrapper {
  background-color: #ffffff;
  color: #022D36;
}
.cardz:hover h3 {
  color: #ffffff;
}
.cardz:hover p {
  color: #f0f0f0;
}
@media screen and (min-width: 768px) {
  .section {
    padding: 0 2em;
  }
  .column {
    flex: 0 1 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 992px) {
  .section {
    padding: 1em 3em;
  }
  .column {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}
/* Question.css */
.accordion-item {
  border-bottom: 1px solid #e5e7eb;
  padding-bottom: 1rem;
  text-align: left;
}

.accordion-header {
  
  justify-content: space-between;
}

.accordion-button {
 
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.accordion-title {
  font-size: 1.125rem;
  font-weight: 600;
  text-align: left;
}

.accordion-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.plus-icon {
  color: #718096; /* gray */
}

.minus-icon {
  color: #4299e1; /* blue */
}

.accordion-content {
  margin-top: 0.5rem;
}

.answer-text {
  color: #4a5568; /* gray */
}
*,
*::before,
*::after {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}



.main {
	display: grid;
	grid-template-columns: 13% 87%;
	width: 100%;
	margin: 10px;
	background: rgb(254, 254, 254);

	z-index: 10;
}

.main-menu {
	overflow: hidden;
	background: #022D36;
	padding-top: 10px;
	border-radius: 15px 0 0 15px;
	font-family: "Roboto", sans-serif;
}

.main-menu h1 {
	display: block;
	font-size: 1.5rem;
	font-weight: 500;
	text-align: center;
	margin: 20px 0 30px;
	color: #fff;
	font-family: "Nunito", sans-serif;
}

.logo {
	display: none;
}

.nav-item {
	position: relative;
	display: block;
}

.nav-item a {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 1rem;
	padding: 15px 0;
	margin-left: 10px;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
}

.nav-item b:nth-child(1) {
	position: absolute;
	top: -15px;
	height: 15px;
	width: 100%;
	background: #fff;
	display: none;
}

.nav-item b:nth-child(1)::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;

	border-bottom-right-radius: 20px;
	background: #022D36;
}

.nav-item b:nth-child(2) {
	position: absolute;
	bottom: -15px;
	height: 15px;
	width: 100%;
	background: #fff;
	display: none;
}

.nav-item b:nth-child(2)::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-top-right-radius: 20px;
	background: #022D36;
}

.nav-item.active b:nth-child(1),
.nav-item.active b:nth-child(2) {
	display: block;
}

.nav-item.active a {
	text-decoration: none;
	color: #000;
	background: rgb(254, 254, 254);
}

.nav-icon {
	width: 60px;
	height: 20px;
	font-size: 20px;
	text-align: center;
}

.nav-text {
	display: block;
	width: 120px;
	height: 20px;
	font-family: "Mulish", sans-serif;
}

/* CONTENT */

.contentor {
	display: grid;
	grid-template-columns: 75% 25%;
  display: flex;
  justify-content: space-between;
}


/* LEFT CONTENT */

.left-content {
	display: grid;
	grid-template-rows: 50% 50%;
	background: #f6f7fb;
	margin: 15px;
	padding: 20px;
	border-radius: 15px;
}

/* ACTIVITIES */

.activities h1 {
	margin: 0 0 20px;
	font-size: 1.4rem;
	font-weight: 700;
}

.activity-container {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-template-rows: repeat(2, 150px);
	grid-column-gap: 10px;
	column-gap: 10px;
}

.img-one {
	grid-area: 1 / 1 / 2 / 2;
}

.img-two {
	grid-area: 2 / 1 / 3 / 2;
}

.img-three {
	display: block;
	grid-area: 1 / 2 / 3 / 4;
}

.img-four {
	grid-area: 1 / 4 / 2 / 5;
}

.img-five {
	grid-area: 1 / 5 / 2 / 6;
}

.img-six {
	display: block;
	grid-area: 2 / 4 / 3 / 6;
}

.image-container {
	position: relative;
	margin-bottom: 10px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px;
	border-radius: 10px;
}

.image-container img {
	width: 100%;
	height: 100%;
	border-radius: 10px;
	object-fit: cover;
}

.overlay {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-end;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(
		180deg,
		transparent,
		transparent,
		#022D36
	);
	border-radius: 10px;
	transition: all 0.6s linear;
}

.image-container:hover .overlay {
	opacity: 0;
}

.overlay h3 {
	margin-bottom: 8px;
	margin-right: 10px;
	color: #fff;
}

/* LEFT BOTTOM */

.left-bottom {
	display: grid;
	grid-template-columns: 55% 40%;
	grid-gap: 40px;
	gap: 40px;

	 max-height: auto;
	 padding-bottom: 30px;;
}

/* WEEKLY SCHEDULE */

.weekly-schedule {
	display: flex;
	flex-direction: column;
}

.weekly-schedule h1 {
	margin-top: 20px;
	font-size: 1.3rem;
	font-weight: 700;
}

.calendar {
	margin-top: 10px;
}

.day-and-activity {
	display: grid;

	grid-template-columns: 15% 60% 25%;
	align-items: center;
	border-radius: 14px;
	margin-bottom: 5px;
	color: #484d53;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px;
}

.activity-one {
	background-color: rgb(124, 136, 224, 0.5);
	background-image: linear-gradient(240deg, rgb(124, 136, 224) 0%, #c3f4fc 100%);
}

.activity-two {
	background-color: #aee2a4a1;
	background-image: linear-gradient(240deg, #e5a243ab 0%, #f7f7aa 90%);
}

.activity-three {
	background-color: #ecfcc376;
	background-image: linear-gradient(240deg, #97e7d1 0%, #ecfcc3 100%);
}

.activity-four {
	background-color: #e6a7c3b5;
	background-image: linear-gradient(240deg, #fc8ebe 0%, #fce5c3 100%);
}

.day {
	display: flex;
	flex-direction: column;
	align-items: center;
	transform: translateY(-10px);
}

.day h1 {
	font-size: 1.6rem;
	font-weight: 600;
}

.day p {
	text-transform: uppercase;
	font-size: 0.9rem;
	font-weight: 600;
	transform: translateY(-3px);
}

.activity {
	border-left: 3px solid #484d53;
}

.participants {
	display: flex;
	margin-left: 20px;
}

.participants img {
	width: 28px;
	height: 28px;
	border-radius: 50%;
	z-index: calc(2 * var(--i));
	margin-left: -10px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px;
}

.act h2 {
	
	font-size: 1.25rem;
	font-weight: 600;
	text-align: left !important;
}

.btn {
	display: block;
	padding: 8px 24px;
	margin: 10px auto;
	font-size: 1.1rem;
	font-weight: 500;
	outline: none;
	text-decoration: none;
	color: #484b57;
	background: rgba(255, 255, 255, 0.9);
	box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
	border: 1px solid rgba(255, 255, 255, 0.3);
	border-radius: 25px;
	cursor: pointer;
}

.btn:hover,
.btn:focus,
.btn:active,
.btn:visited {
	transition-timing-function: cubic-bezier(0.6, 4, 0.3, 0.8);
	animation: gelatine 0.5s 1;
}

@keyframes gelatine {
	0%,
	100% {
		transform: scale(1, 1);
	}
	25% {
		transform: scale(0.9, 1.1);
	}
	50% {
		transform: scale(1.1, 0.9);
	}
	75% {
		transform: scale(0.95, 1.05);
	}
}

/* PERSONAL BESTS */

.personal-bests {
	display: block;
}

.personal-bests h1 {
	margin-top: 20px;
	font-size: 1.3rem;
	font-weight: 700;
}

.personal-bests-container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 150px);
	grid-gap: 10px;
	gap: 10px;
	margin-top: 10px;
}

.best-item {
	display: flex;
	grid-gap: 20px;
	gap: 20px;
	width: 50%;
	height: 100%;
	border-radius: 15px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px;
}

.box-one {
	flex-direction: row;
	align-items: center;
	justify-content: center;

	background-color: rgba(185, 159, 237, 0.6);
	padding: 15px;
	font-size: 1rem;
	font-weight: 700;
}

.box-one img {
	max-width: 100px;
	aspect-ratio: 4/3;
}

.box-two {
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	font-size: 0.9rem;
	font-weight: 700;
	padding: 10px;
	
	background-color: rgba(238, 184, 114, 0.6);
}

.box-two img {
	max-width: 90px;
	aspect-ratio: 3/2;
	align-self: flex-end;
}

.box-three {
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	font-size: 0.9rem;
	font-weight: 700;
	padding: 10px;

	background-color: rgba(184, 224, 192, 0.6);
}

.box-three img {
	max-width: 70px;
	aspect-ratio: 1/1;
	align-self: flex-end;
}

/* RIGHT CONTENT */

.right-content {
	display: grid;
	grid-template-rows: 5% 20% 75%;
	background: #f6f7fb;
	margin: 15px 15px 15px 0;
	padding: 10px 0;
	border-radius: 15px;
}

/* USER INFO */

.user-info {
	display: grid;
	grid-template-columns: 30% 55% 15%;
	align-items: center;
	padding: 0 10px;
}

.icon-container {
  width: 50px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
  border-radius: 50%; /* Makes the background round */
  background-color: #ccc; /* Sets the initial background color to grey */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.icon-container:hover {
  background-color: #999; /* Changes the background color on hover */
}
.icon-container {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  min-width: 220px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.icon-container:hover .dropdown-content {
  display: block;
  
}

.dropdown-content a {
  display: block;
  padding-left: 8px;
 padding-top: 5px;
  text-decoration: none;
  color: #333;
  
}

.dropdown-content a:hover {
  background-color: #f0f0f0;
  
}
.user-info h4 {
	margin-left: 40px;
}

.user-info img {
	width: 60px;
}

/* ACTIVE CALORIES  */

.active-calories {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: rgb(214, 227, 248);
	padding: 0 10px;
	margin: 15px 10px 0;
	border-radius: 15px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px;
}

.active-calories h1 {
	margin-top: 10px;
	font-size: 1.2rem;
}

.active-calories-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	grid-gap: 10px;
	gap: 10px;
}

.calories-content p {
	font-size: 1rem;
}

.calories-content p span {
	font-weight: 700;
}

.box {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;
	padding: 10px 0;
	/* width: 200px; */
}

.box h2 {
	position: relative;
	text-align: center;
	font-size: 1.25rem;
	color: rgb(91, 95, 111);
	font-weight: 600;
}

.box h2 small {
	font-size: 0.8rem;
	font-weight: 600;
}

.circle {
	position: relative;
	width: 80px;
	aspect-ratio: 1/1;
	background: conic-gradient(
		from 0deg,
		#590b94 0%,
		#590b94 0% var(--i),
		#b3b2b2 var(--i),
		#b3b2b2 100%
	);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.circle::before {
	content: "";
	position: absolute;
	inset: 10px;
	background-color: rgb(214, 227, 248);
	border-radius: 50%;
}

/* MOBILE PERSONAL BESTS  */

.mobile-personal-bests {
	display: none;
}

/* FRIEND ACTIVITIES  */

.friends-activity {
	display: flex;
	flex-direction: column;
}

.friends-activity h1 {
	font-size: 1.2rem;
	font-weight: 700;
	margin: 15px 0 10px 15px;
}

.card-container {
	display: flex;
	flex-direction: column;
	grid-gap: 10px;
	gap: 10px;
}

.card {
	background-color: #fff;
	margin: 0 10px;
	padding: 5px 7px;
	border-radius: 15px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px;
}

.card-two {
	display: block;
}

.card-user-info {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-bottom: 5px;
}

.card-user-info img {
	width: 25px;
	aspect-ratio: 1/1;
	border-radius: 50%;
	object-fit: cover;
}

.card-user-info h2 {
	font-size: 1rem;
	font-weight: 700;
	margin-left: 5px;
}

.card-img {
	display: block;
	width: 100%;
	aspect-ratio: 7/4;
	margin-bottom: 10px;
	object-fit: cover;
	border-radius: 10px;
	object-position: 50% 30%;
}

.card p {
	font-size: 0.9rem;
	padding: 0 5px 5px;
}

@media (max-width: 1500px) {
	main {
		grid-template-columns: 6% 94%;
	}

	.main-menu h1 {
		display: none;
	}

	.logo {
		display: block;
		width: 30px;
		margin: 20px auto;
	}

	.nav-text {
		display: none;
	}

	.contentor {
		grid-template-columns: 70% 30%;
	}
}

@media (max-width: 1310px) {
	main {
		grid-template-columns: 8% 92%;
		margin: 30px;
	}

	.contentor {
		grid-template-columns: 65% 35%;
	}

	.day-and-activity {
		margin-bottom: 10px;
	}

	.btn {
		padding: 8px 16px;
		margin: 10px 0;
		margin-right: 10px;
		font-size: 1rem;
	}

	.personal-bests-container {
		grid-template-rows: repeat(3, 98px);
		grid-gap: 15px;
		gap: 15px;
	}

	.box-one {
		flex-direction: row;
		justify-content: space-between;
		grid-area: 1 / 1 / 2 / 3;
		padding: 10px;
		font-size: 0.9rem;
	}

	.box-one img {
		max-width: 80px;
	}

	.box-two {
		flex-direction: row;
		justify-content: space-between;
		grid-area: 2 / 1 / 3 / 3;
	}

	.box-two img {
		max-width: 70px;
		align-self: center;
	}

	.box-three {
		flex-direction: row;
		justify-content: space-between;
		grid-area: 3 / 1 / 4 / 3;
	}

	.box-three img {
		max-width: 60px;
		align-self: center;
	}

	.right-content {
		grid-template-rows: 4% 20% 76%;
		margin: 15px 15px 15px 0;
	}
}

@media (max-width: 1150px) {
	.contentor {
		grid-template-columns: 60% 40%;
	}

	.left-content {
		grid-template-rows: 50% 50%;
		margin: 15px;
		padding: 20px;
		height: 900px;
	}

	.btn {
		padding: 8px 8px;
		font-size: 0.9rem;
	}

	.personal-bests-container {
		grid-template-rows: repeat(3, 70px);
		grid-gap: 10px;
		gap: 10px;
	}

	.activity-container {
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(2, 150px);
	}

	.img-one {
		grid-area: 1 / 1 / 2 / 2;
	}

	.img-two {
		grid-area: 2 / 1 / 3 / 2;
	}

	.img-three {
		display: none;
	}

	.img-four {
		grid-area: 1 / 2 / 2 / 3;
	}

	.img-five {
		grid-area: 1 / 3 / 2 / 4;
	}

	.img-six {
		grid-area: 2 / 2 / 3 / 4;
	}

	.left-bottom {
		grid-template-columns: 100%;
		grid-gap: 0;
		gap: 0;
	}

	.right-content {
		grid-template-rows: 4% 19% 36% 41%;
	}

	.personal-bests {
		display: none;
	}

	.mobile-personal-bests {
		display: block;
		margin: 0 10px;
	}

	.mobile-personal-bests h1 {
		margin-top: 20px;
		font-size: 1.2rem;
	}

	.card-two {
		display: none;
	}

	.card-img {
		aspect-ratio: 16/9;
	}
}

@media (max-width: 1050px) {
	.right-content {
		grid-template-rows: 5% 19% 36% 40%;
	}
}

@media (max-width: 910px) {
	main {
		grid-template-columns: 10% 90%;
		margin: 20px;
	}

	.contentor {
		grid-template-columns: 55% 45%;
	}

	.left-content {
		grid-template-rows: 50% 50%;
		padding: 30px 20px 20px;
	}

	.activity-container {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 150px);
	}

	.img-one {
		grid-area: 1 / 1 / 2 / 2;
	}

	.img-two {
		grid-area: 2 / 1 / 3 / 2;
	}

	.img-three {
		display: none;
	}

	.img-four {
		grid-area: 1 / 2 / 2 / 3;
	}

	.img-five {
		grid-area: 2 / 2 / 3 / 3;
	}

	.img-six {
		display: none;
	}
}

@media (max-width: 800px) {
	.content {
		grid-template-columns: 52% 48%;
	}
}

@media (max-width: 700px) {
	main {
		grid-template-columns: 15% 85%;
	}

	.contentor {
		grid-template-columns: 100%;
		grid-template-rows: 45% 55%;
		grid-template-areas:
			"rightContent"
			"leftContent";
	}

	.left-content {
		grid-area: leftContent;
		grid-template-rows: 45% 55%;
		padding: 10px 20px 10px;
	}

	.right-content {
		grid-area: rightContent;
		grid-template-rows: 5% 40% 50%;
		margin: 15px 15px 0 15px;
		padding: 10px 0 0;
		grid-gap: 15px;
		gap: 15px;
	}

	.activities,
	.weekly-schedule {
		margin-top: 10px;
	}

	.active-calories-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		grid-gap: 10px;
		gap: 10px;
	}

	.friends-activity {
		display: none;
	}
}
/* Add your custom CSS here */
    body {
      margin: 0;
      padding: 0;
    }
    main {
      display: flex;
      flex-direction: column;
    }
    .main-menu {
      order: 2; /* Change the order to 2 for small devices */
    }

.contentor {
  display: flex;
  flex-direction: column;
  
}

footer {
  margin-top: auto; /* Push footer to the bottom */
}
    @media screen and (min-width: 768px) {
      .contentor {
        flex-direction: row;
      }
      .left-content {
        order: 1; /* Change the order to 2 for small devices */
      }
      .right-content {
        order: 2; /* Change the order to 1 for small devices */
      }
    }
    
    
    /*****Details css***/

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

/* Shadows */
.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

/* Borders */
.rounded {
  border-radius: 0.25rem;
}

.rounded-full {
  border-radius: 9999px;
}

/* Buttons */
.inline-flex {
  display: inline-flex;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
          user-select: none;
}

.appearance-none {
  -webkit-appearance: none;
  appearance: none;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.space-x-1 {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}

.border {
  border-width: 1px;
}

.bg-white {
  background-color: #ffffff;
}

.bg-yellow-100 {
  background-color: #fefcbf;
}

.bg-green-100 {
  background-color: #c6f6d5;
}

.bg-blue-100 {
  background-color: #c7ebf2;
}

.bg-indigo-100 {
  background-color: #d4d7fb;
}

.bg-gray-100 {
  background-color: #f7fafc;
}

.bg-blue-700 {
  background-color:#022D36;
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, #10b981, #3b82f6);
}

/* Text */
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-slate-900 {
  color: #111827;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-gray-600 {
  color: #718096;
}

.text-yellow-800 {
  color: #7f9c2d;
}

.text-green-800 {
  color: #047857;
}

.text-blue-800 {
  color: #185adb;
}

.text-indigo-800 {
  color: #4338ca;
}

.text-gray-800 {
  color: #4b5563;
}

.text-stone-500 {
  color: #718096;
}

.text-slate-700 {
  color: #374151;
}

.text-slate-500 {
  color: #6b7280;
}

.text-slate-600 {
  color: #4b5563;
}

.text-white {
  color: #ffffff;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

/* Images */
.object-center {
  object-position: center;
}

.object-cover {
  object-fit: cover;
}

/* Flex */
.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

/* Padding and Margins */
.p-5 {
  padding: 1.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.-mt-14 {
  margin-top: -3.5rem;
}

.ml-auto {
  margin-left: auto;
}

/* Width */
.w-full {
  width: 100%;
}

.md:w-1/2 {
  width: 50%;
}

.w-[90px] {
  width: 90px;
}

/* Height */
.h-[140px] {
  height: 140px;
}

.h-[90px] {
  height: 90px;
}

/* Overflow */
.overflow-hidden {
  overflow: hidden;
}

/* Border */
.border-4 {
  border-width: 4px;
}

/* Miscellaneous */
.rounded-sm {
  border-radius: 0.375rem;
}

.self-start {
  align-self: flex-start;
}

.leading-3 {
  line-height: 0.75rem;
}

.gap-3 {
  grid-gap: 0.75rem;
  gap: 0.75rem;
}

.gap-2 {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

/* Transitions */
.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

/* Hover Effects */
.hover\:border-gray-300:hover {
  border-color: #d1d5db;
}

.hover\:bg-gray-100:hover {
  background-color: #f3f4f6;
}

.hover\:bg-blue-600:hover {
  background-color: #2563eb;
}

.hover\:text-white:hover {
  color: #ffffff;
}

/* Focus Effects */
.focus\:border-gray-300:focus {
  border-color: #d1d5db;
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:ring-2:focus {
  box-shadow: 0 0 0 2px rgba(163, 169, 174, 0.5);
}

.focus\:ring-blue-300:focus {
  box-shadow: 0 0 0 2px rgba(164, 202, 254, 0.5);
}

.focus\:ring-gray-300:focus {
  box-shadow: 0 0 0 2px rgba(209, 213, 219, 0.5);
}

/* Active Effects */
.active\:bg-white:active {
  background-color: #ffffff;
}

.active\:bg-blue-700:active {
  background-color: #2c5282;
}

.active\:text-white:active {
  color: #ffffff;
}

.detailer {
  
  overflow-y: auto;
  width: 60rem;
  
margin: 50px; 
  padding-bottom: 70px;
}
  @media only screen and (max-width: 859px) {
.detailer{

  width: 350px;
margin-bottom:80px;
  max-height: calc(100vh - 80px); 
  overflow-y: auto;
margin: 50px auto 0 auto; 
}
    .best-item {
  width: 100%;   
    }
    
    
  }


:root {
  --body-font: "Inter", sans-serif;
  --theme-bg: #f5f9fb;
  --body-color: #808191;
  --button-bg: #022D36;
  --border-color: rgb(128 129 145 / 24%);
  --video-bg: #022D36;
  --delay: 0s;
}


.containeri {
  background-color: var(--theme-bg);
 
  height: 95vh;
  display: flex;
  overflow: scroll;
  width: 100%;
  border-radius: 20px;
  font-size: may15px;
  font-weight: 500;
  
  position: relative;
}
/* Custom scrollbar styling for WebKit browsers */
.containeri::-webkit-scrollbar {
  width: 16px; /* Increase scrollbar width */
}

.containeri::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded corners for thumb */
}

.containeri::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Scrollbar thumb color on hover */
}

.containeri::-webkit-scrollbar-track {
  background: #f1f1f1; /* Scrollbar track color */
}

.sidebar {
  width: 220px;
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  transition-duration: 0.2s;
  overflow-y: auto;
  overflow-x: hidden;
}
.sidebar .lowgo {
  display: none;
  width: 30px;
  height: 30px;
  background-color: #22b07d;
  flex-shrink: 0;
  color: #fff;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
}
.sidebar .logo-expand {
  text-decoration: none;
  color: #fff;
  font-size: 19px;
  font-weight: 600;
  line-height: 34px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.sidebar .logo-expand:before {
  content: "";
  position: absolute;
  top: -30px;
  left: 0;
  background: var(--theme-bg);
  width: 200px;
  height: 70px;
  z-index: -1;
}
.sidebar-link:hover, .sidebar-link.is-active {
  color: #fff;
  font-weight: 600;
}
.sidebar-link:hover:nth-child(2n+1) svg, .sidebar-link.is-active:nth-child(2n+1) svg {
  background: #ff7551;
}
.sidebar-link:hover:nth-child(2n) svg, .sidebar-link.is-active:nth-child(2n) svg {
  background: #32a7e2;
}
.sidebar-link:hover:nth-child(2n+3) svg, .sidebar-link.is-active:nth-child(2n+3) svg {
  background: #6c5ecf;
}
.sidebar.collapse {
  width: 90px;
  border-right: 1px solid var(--border-color);
}
.sidebar.collapse .logo-expand,
.sidebar.collapse .side-title {
  display: none;
}
.sidebar.collapse .lowgo {
  display: flex;
}
.sidebar.collapse .side-wrapper {
  width: 30px;
}
.sidebar.collapse .side-menu svg {
  margin-right: 30px;
}

@keyframes bottom {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.side-menu {
  display: flex;
  flex-direction: column;
}
.side-menu a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--body-color);
}
.side-menu a + a {
  margin-top: 26px;
}
.side-menu svg {
  width: 30px;
  padding: 8px;
  border-radius: 10px;
  background-color: var(--button-bg);
  flex-shrink: 0;
  margin-right: 16px;
  fill: white;
}
.side-menu svg:hover {
  color: #fff;
}

.side-title {
  font-size: 12px;
  letter-spacing: 0.07em;
  margin-bottom: 24px;
}

.side-wrapper {
  border-bottom: 1px solid var(--border-color);
  padding: 36px 0;
  width: 145px;
}
.side-wrapper + .side-wrapper {
  border-bottom: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-grow: 1;
}
.dpz{
   height: 20px;
   width:50px ; 
  
}
.header {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 30px;
}

.search-bar {
  height: 34px;
  display: flex;
  width: 100%;
  max-width: 450px;
}
.search-bar input {
  width: 100%;
  height: 100%;
  border: none;
  background-color: var(--button-bg);
  border-radius: 8px;
  font-family: var(--body-font);
  font-size: 14px;
  font-weight: 500;
  padding: 0 40px 0 16px;
  box-shadow: 0 0 0 2px rgba(134, 140, 160, 0.02);
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.966 56.966' fill='%23717790c7'%3e%3cpath d='M55.146 51.887L41.588 37.786A22.926 22.926 0 0046.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 00.083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z'/%3e%3c/svg%3e");
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: 96%;
  color: #fff;
}

.user-settings {
  display: flex;
  align-items: center;
  padding-left: 20px;
  flex-shrink: 0;
  margin-left: auto;
}
.user-settings svg {
  width: 10px;
  flex-shrink: 0;
}
@media screen and (max-width: 575px) {
  .user-settings svg {
    display: none;
  }
}
.user-settings .notify {
  position: relative;
}
.user-settings .notify svg {
  width: 20px;
  margin-left: 24px;
  flex-shrink: 0;
}
.user-settings .notify .notification {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ec5252;
  position: absolute;
  right: 1px;
  border: 1px solid var(--theme-bg);
  top: -2px;
}
@media screen and (max-width: 575px) {
  .user-settings .notify .notification {
    display: none;
  }
}
.user-img {
  width: 100px;
  
  flex-shrink: 0;
  object-fit: cover;

}
.user-name {
  color: black;
  font-size: 30px;
margin-right:40px;
margin-top:13px;
  font-weight: bold;
}
@media screen and (max-width: 575px) {
  .user-name {
    display: none;
  }
}

.main-container {
  display: flex;
  flex-direction: column;
  padding: 0 30px 30px;
  flex-grow: 1;
  overflow: auto;
}

.anim {
  animation: bottom 0.8s var(--delay) both;
}

.main-header {
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  padding-bottom: 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, #1f1d2b 0%, #1f1d2b 78%, rgba(31, 29, 43, 0) 100%);
  z-index: 11;
}

.small-header {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  margin: 30px 0 20px;
}

.main-blogs {
  display: flex;
  align-items: center;
}
.main-blog__author {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}
.main-blog__author.tips {
  flex-direction: column-reverse;
  align-items: flex-start;
}
.main-blog__title {
  font-size: 25px;
  max-width: 12ch;
  font-weight: 600;
  letter-spacing: 1px;
  color: #fff;
  margin-bottom: 30px;
}
.main-blog {
  background-image: url("https://assets.codepen.io/3364143/skate-removebg-preview.png");
  background-size: 80%;
  background-position-x: 150px;
  background-color: #31abbd;
  display: flex;
  flex-direction: column;
  width: 65%;
  padding: 30px;
  border-radius: 20px;
  align-self: stretch;
  overflow: hidden;
  position: relative;
  transition: background 0.3s;
  background-repeat: no-repeat;
}
.main-blog + .main-blog {
  margin-left: 20px;
  width: 35%;
  background-image: url(https://c0.anyrgb.com/images/1020/945/venice-beach-2018-outdoors-sport-men-jumping-desert-sunset-extreme-sports-one-person-action.jpg);
  background-color: unset;
  background-position-x: 0;
  background-size: 139%;
  filter: saturate(1.4);
}
.main-blog + .main-blog .author-img {
  border-color: rgba(255, 255, 255, 0.75);
  margin-top: 14px;
}
.main-blog + .main-blog .author-img__wrapper svg {
  border-color: #ffe6b2;
  color: #e7bb7d;
}
.main-blog + .main-blog .author-detail {
  margin-left: 0;
}
@media screen and (max-width: 905px) {
  .main-blog, .main-blog + .main-blog {
    width: 50%;
    padding: 30px;
  }
  .main-blog {
    background-size: cover;
    background-position-x: center;
    background-blend-mode: overlay;
  }
}
.main-blog__time {
  background: rgba(21, 13, 13, 0.44);
  color: #fff;
  padding: 3px 8px;
  font-size: 12px;
  border-radius: 6px;
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.author-img {
  width: 52px;
  height: 52px;
  border: 1px solid rgba(255, 255, 255, 0.75);
  padding: 4px;
  border-radius: 50%;
  object-fit: cover;
}
.author-img__wrapper {
  position: relative;
  flex-shrink: 0;
}
.author-img__wrapper svg {
  width: 16px;
  padding: 2px;
  background-color: #fff;
  color: #0daabc;
  border-radius: 50%;
  border: 2px solid #0daabc;
  position: absolute;
  bottom: 5px;
  right: 0;
}
.author-name {
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 8px;
}
.author-info {
  font-size: 13px;
  font-weight: 400;
  color: #fff; 
}
.author-detail {
  margin-left: 16px;
}

.seperate {
  width: 3px;
  height: 3px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 6px;
}
.seperate.video-seperate {
  background-color: var(--body-color);
}

.videozi{
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
@media screen and (max-width: 980px) {
  .videozi {
    grid-template-columns: repeat(2, 1fr);
  }
}

.vidiyo {
  position: relative;
  background-color: var(--video-bg);
  border-radius: 20px;
  overflow: hidden;
  transition: 0.4s;
}
.video-wrapper {
  position: relative;
}
.video-name {
  color: #fff;
  font-size: 20px;
  line-height: 1.4em;
  padding: 12px 20px 0;
  overflow: hidden;
  background-color: var(--video-bg);
  z-index: 9;
  position: relative;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.video-view {
  font-size: 12px;
  padding: 12px 20px 20px;
  background-color: var(--video-bg);
  position: relative;
  color:#fff;
}
.video-by {
  transition: 0.3s;
  padding: 20px 20px 0px;
  display: inline-flex;
  position: relative;
  color:#fff;
  font-size:20px;
  font-weight:bold;
}
.video-by:before {
  content: "";
  background-color: #22b07d;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  top: 26px;
  right: 5px;
}
.video-by.offline:before {
  background-color: #ff7551;
}
.video-time {
  position: absolute;
  background: rgba(21, 13, 13, 0.44);
  color: rgba(255, 255, 255, 0.85);
  padding: 3px 8px;
  font-size: 12px;
  border-radius: 6px;
  top: 10px;
  z-index: 1;
  right: 8px;
}
.video:hover video {
  transform: scale(1.6);
  transform-origin: center;
}
.video:hover .video-time {
  display: none;
}
.video:hover .video-author {
  bottom: -65px;
  transform: scale(0.6);
  right: -3px;
  z-index: 10;
}
.video:hover .video-by {
  opacity: 0;
}
.video-author {
  position: absolute;
  right: 10px;
  transition: 0.4s;
  bottom: -25px;
}
.video-author svg {
  background-color: #0aa0f7;
  color: #fff;
  border-color: var(--video-bg);
}

video {
  max-width: 100%;
  width: 100%;
  border-radius: 20px 20px 0 0;
  display: block;
  cursor: pointer;
  transition: 0.4s;
}

.stream-area {
  display: none;
}
@media screen and (max-width: 940px) {
  .stream-area {
    flex-direction: column;
  }
  .stream-area .video-stream {
    width: 100%;
  }
  .stream-area .chat-stream {
    margin-left: 0;
    margin-top: 30px;
  }
  .stream-area .video-js.vjs-fluid {
    min-height: 250px;
  }
  .stream-area .msg__content {
    max-width: 100%;
  }
}

.show .stream-area {
  display: flex;
}
.show .main-header,
.show .main-blogs,
.show .small-header,
.show .videozi {
  display: none;
}

.video-stream {
  width: 65%;
  object-fit: cover;
  transition: 0.3s;
}
.video-stream:hover .video-js .vjs-big-play-button {
  opacity: 1;
}

.video-p {
  margin-right: 12px;
  object-fit: cover;
  flex-shrink: 0;
  border-radius: 50%;
  position: relative;
  top: 0;
  left: 0;
}
.video-p .author-img {
  border: 0;
}
.video-p-wrapper {
  display: flex;
  align-items: center;
}
.video-p-wrapper .author-img {
  border: 0;
}
.video-p-wrapper svg {
  width: 20px;
  padding: 4px;
}
@media screen and (max-width: 650px) {
  .video-p-wrapper {
    flex-direction: column;
  }
  .video-p-wrapper .button-wrapper {
    margin: 20px auto 0;
  }
  .video-p-wrapper .video-p-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .video-p-wrapper .video-p {
    margin-right: 0;
  }
}
.video-p-sub {
  font-size: 12px;
}
.video-p-title {
  font-size: 24px;
  color: #fff;
  line-height: 1.4em;
  margin: 16px 0 20px;
}
.video-p-subtitle {
  font-size: 14px;
  line-height: 1.5em;
  max-width: 60ch;
}
.video-p-subtitle + .video-p-subtitle {
  margin-top: 16px;
}
.video-p-name {
  margin-bottom: 8px;
  color: #fff;
  display: flex;
  align-items: center;
}
.video-p-name:after {
  content: "";
  width: 6px;
  height: 6px;
  background-color: #22b07d;
  border-radius: 50%;
  margin-left: 8px;
  display: inline-block;
}
.video-p-name.offline:after {
  background-color: #ff7551;
}

.video-content {
  width: 100%;
}

.button-wrapper {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.like {
  display: flex;
  align-items: center;
  background-color: var(--button-bg);
  color: #fff;
  border: 0;
  font-family: var(--body-font);
  border-radius: 8px;
  padding: 10px 16px;
  font-size: 14px;
  cursor: pointer;
}
.like.red {
  background-color: #ea5f5f;
}
.like svg {
  width: 18px;
  flex-shrink: 0;
  margin-right: 10px;
  padding: 0;
}
.like + .like {
  margin-left: 16px;
}

.video-stats {
  margin-left: 30px;
}

.video-detail {
  display: flex;
  margin-top: 30px;
  width: 100%;
}

.chat-header {
  display: flex;
  align-items: center;
  padding: 20px 0;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #;
  left: 0;
  z-index: 1;
  border-bottom: 1px solid var(--border-color);
}
.chat-header svg {
  width: 15px;
  margin-right: 6px;
  flex-shrink: 0;
}
.chat-header span {
  margin-left: auto;
  color: var(--body-color);
  font-size: 12px;
  display: flex;
  align-items: center;
}

.chat-stream {
  flex-grow: 1;
 
}

.chat {
  background-color: #022D36;
  border-radius: 20px;
  padding: 0 20px;
  width: 30rem;
  max-height: 414px;
  overflow: auto;
}
@media only screen and (max-width: 767px) {
  .chat {
    width: calc(100% - 10px); /* Adjust the subtraction value as needed */
    max-width: none;
  }
}
.chat-footer {
  display: flex;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  left: 0;
  width: calc(100% + 20px);
  padding-bottom: 12px;
  background-color: #022D36;
}
.chat-footer input {
  width: 100%;
  border: 0;
  background-color: #2d303e;
  border-radius: 20px;
  font-size: 12px;
  color: #fff;
  margin-left: -10px;
  padding: 12px 40px;
  font-weight: 500;
  font-family: var(--body-font);

}
.chat-footer input::placeholder {
  color: #6c6e78;
}
.chat-footer:before {
  content: "";
  position: absolute;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M21.435 2.582a1.933 1.933 0 00-1.93-.503L3.408 6.759a1.92 1.92 0 00-1.384 1.522c-.142.75.355 1.704 1.003 2.102l5.033 3.094a1.304 1.304 0 001.61-.194l5.763-5.799a.734.734 0 011.06 0c.29.292.29.765 0 1.067l-5.773 5.8c-.428.43-.508 1.1-.193 1.62l3.075 5.083c.36.604.98.946 1.66.946.08 0 .17 0 .251-.01.78-.1 1.4-.634 1.63-1.39l4.773-16.075c.21-.685.02-1.43-.48-1.943z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: center;
  width: 18px;
  height: 18px;
  background-color: #6c5ecf;
  padding: 4px;
  border-radius: 50%;
  right: 16px;
}
.chat-vid__title {
  color: #fff;
  font-size: 18px;
}
.chat-vid__container {
  margin-top: 40px;
}
.chat-vid__wrapper {
  display: flex;
  align-items: center;
  margin-top: 26px;
}
.chat-vid__name {
  color: #fff;
  font-size: 14px;
  line-height: 1.3em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
}
.chat-vid__img {
  width: 100px;
  height: 80px;
  border-radius: 10px;
  object-fit: cover;
  object-position: right;
  margin-right: 16px;
  transition: 0.3s;
}
.chat-vid__img:hover {
  transform: scale(1.02);
}
.chat-vid__content {
  max-width: 20ch;
}
.chat-vid__by, .chat-vid__info {
  color: var(--body-color);
  font-size: 13px;
}
.chat-vid__by {
  margin: 6px 0;
}
.chat-vid__button {
  background-color: #6c5ecf;
  border: 0;
  color: #fff;
  font-size: 13px;
  margin-top: 26px;
  display: flex;
  padding: 0 10px;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;
}
.chat-vid__button:hover {
  background-color: #5847d0;
}

.message {
  display: flex;
  align-items: center;
  margin-top: 18px;
}
.message:last-child {
  margin-bottom: 18px;
}
.message-container .author-img__wrapper svg {
  width: 15px;
}

.msg__name {
  font-size: 20px;
  color:#fff;
}
.msg__content {
  line-height: 1.4em;
  max-width: 26ch;
  color:#fff;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.video-js .vjs-control-bar {
  display: flex;
  align-items: center;
}

.vjs-poster {
  background-size: 150%;
}

.video-js .vjs-control-bar {
  width: 100%;
  position: absolute;
  bottom: 14px;
  padding-left: 36px;
  left: 14px;
  width: calc(100% - 28px);
  right: 0;
  border-radius: 10px;
  height: 4em;
  background-color: #2b333f;
  background-color: rgba(43, 51, 63, 0.7);
}
@media screen and (max-width: 625px) {
  .video-js .vjs-control-bar {
    padding-left: 0;
  }
}

.video-js:hover .vjs-big-play-button {
  background-color: rgba(43, 51, 63, 0.5);
}

.video-js .vjs-big-play-button {
  transition: 0.3s;
  opacity: 0;
  border: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-js .vjs-big-play-button:hover {
  background-color: rgba(43, 51, 63, 0.7);
  border-color: transparent;
}

.vjs-play-control:after {
  content: "LIVE";
  position: absolute;
  left: -66px;
  top: 7px;
  background-color: #8941e3;
  height: 24px;
  font-family: var(--body-font);
  font-size: 10px;
  padding: 0 12px 0 26px;
  display: flex;
  font-weight: 700;
  letter-spacing: 0.03em;
  align-items: center;
  border-radius: 6px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='%23fff' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-circle'%3e%3ccircle cx='12' cy='12' r='10'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 6px;
  background-position: 12px;
}
@media screen and (max-width: 625px) {
  .vjs-play-control:after {
    display: none;
  }
}

.vjs-menu-button-inline .vjs-menu {
  top: 4px;
}

.video-js .vjs-control:before,
.video-js .vjs-time-control {
  line-height: 40px;
}

.video-js .vjs-tech {
  object-fit: cover;
}

button.vjs-play-control.vjs-control.vjs-button {
  margin-left: 40px;
}
@media screen and (max-width: 625px) {
  button.vjs-play-control.vjs-control.vjs-button {
    margin-left: 0;
  }
}

.vjs-icon-fullscreen-enter:before,
.video-js .vjs-fullscreen-control:before {
  content: "";
  position: absolute;
  display: block;
  
  background-size: 11px;
  background-position: center;
  background-position-y: 14px;
  background-repeat: no-repeat;
  opacity: 0.6;
}

.vjs-playback-rate .vjs-playback-rate-value {
  font-size: 1.1em;
  line-height: 3.5em;
  opacity: 0.6;
  font-weight: 700;
  font-family: var(--body-font);
}

.video-js .vjs-playback-rate {
  width: 2.2em;
}

.video-js.vjs-fluid {
  border-radius: 20px;
  overflow: hidden;
  min-height: 414px;
}

@media screen and (max-width: 735px) {
  .main-blogs {
    flex-wrap: wrap;
  }

  .main-blog,
.main-blog + .main-blog {
    width: 100%;
  }

  .videozi {
    grid-template-columns: 1fr;
  }

  .main-blog + .main-blog {
    margin-left: 0;
    margin-top: 20px;
    background-size: cover;
  }
}
@media screen and (max-width: 475px) {
  .main-blog__title {
    font-size: 20px;
  }

  .author-name {
    font-size: 14px;
  }

  .main-blog__author {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .author-detail {
    margin-left: 0;
  }

  .main-blog .author-img {
    margin-top: 14px;
  }

  .main-container {
    padding: 0 20px 20px;
  }

  .header {
    padding: 20px;
  }

  .sidebar.collapse {
    width: 40px;
  }

  .sidebar {
    align-items: center;
  }



  .container {
    height: auto;
    border-radius: 0;
    max-height: 100%;
  }
}
::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(21, 20, 26, 0.63);
  border-radius: 10px;
}


  .viannie {
    display: flex;
    flex-wrap: wrap;
    max-width: 100;
   
  }

main {
  width: min(980px, 95%);
 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  
}
main .heada{
  margin-bottom: 55px;
}
@media screen and (max-width: 600px) {
  main .heada{
    margin-bottom: 70px !important;
  }
}
main .heada h1 {
  font-weight: 800;
  font-size: 25px;
  margin: 5px -5px 15px;
}
main .heada span {
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  color: #D0312D;
}
main .heada p {
 
  line-height: 1.6;
  color: #565656;
}
main .item {
 
  height: 300px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  width: calc(33.33% - 10px);
    margin-bottom: 20px;
    margin-left: 10px;
}
main .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  inset: 0;
}
main .item .overlayzi {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 28px 25px;
  color: #fff;
  background: #020024;
  background: linear-gradient(0deg, #020024 0%, #000032a1 3%, #17d9ff00 100%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
main .item .overlayzi span {
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 60px;
  padding: 10px 20px;
  display: inline-block;
}
main .item .overlayzi > div h2 {
 
 text-align: left;
  margin-bottom: 5px;

}

.owl-nav {
  position: absolute;
  top: -100px;
  right: 0;
}

@media (max-width: 1024px) {
    .item {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    .viannie {
      flex-direction: column;
      align-items: center;
      
    }

  main .item {
      width: 100%; 
      margin-left: -20px;
    }
  }

:root {
  --c-gray-900: #000000;
  --c-gray-800: #1f1f1f;
  --c-gray-700: #2e2e2e;
  --c-gray-600: #313131;
  --c-gray-500: #969593;
  --c-gray-400: #a6a6a6;
  --c-gray-300: #bdbbb7;
  --c-gray-200: #f1f1f1;
  --c-gray-100: #ffffff;
  --c-green-500: #45ffbc;
  --c-olive-500: #e3ffa8;
  --c-white: var(--c-gray-100);
  --c-text-primary: var(--c-gray-100);
  --c-text-secondary: var(--c-gray-200);
  --c-text-tertiary: var(--c-gray-500);
}

.body {
  
 background-color: #f5f9fb;
}

.img {
  display: block;
  max-width: 100%;
}

button,
select,
input,
textarea {
  font: inherit;
}

a {
  color: inherit;
}

.responsive-wrapper {
  width: 90%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}
.error {
  color: red;
  font-size: 0.875rem; /* Optional: Adjust the font size as needed */
  margin-top: 0.25rem; /* Optional: Adds space above the error message */
}

.app {
  min-height: 80vh;
  width: 95%;
 

  padding: 2vw 4vw 6vw;
  display: flex;
  flex-direction: column;
}

.app-header {
  display: grid;
  grid-template-columns: minmax(-webkit-min-content, 175px) minmax(-webkit-max-content, 1fr) minmax(-webkit-max-content, 400px);
  grid-template-columns: minmax(min-content, 175px) minmax(max-content, 1fr) minmax(max-content, 400px);
  -moz-column-gap: 4rem;
       grid-column-gap: 4rem;
       column-gap: 4rem;
  align-items: flex-end;
}
@media (max-width: 1200px) {
  .app-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--c-gray-600);
  }
}

@media (max-width: 1200px) {
  .app-header-navigation {
    display: none;
  }
}

.app-header-actions {
  display: flex;
  align-items: center;
}
@media (max-width: 1200px) {
  .app-header-actions {
    display: none;
  }
}

.app-header-actions-buttons {
  display: flex;
  border-left: 1px solid var(--c-gray-600);
  margin-left: 2rem;
  padding-left: 2rem;
}
.app-header-actions-buttons > * + * {
  margin-left: 1rem;
}

.app-header-mobile {
  display: none;
}
@media (max-width: 1200px) {
  .app-header-mobile {
    display: flex;
  }
}

.app-body {
  height: 100%;
  display: grid;
  grid-template-columns: minmax(-webkit-min-content, 175px) minmax(-webkit-max-content, 1fr) minmax(-webkit-min-content, 40px);
  grid-template-columns: minmax(-webkit-min-content, 175px) minmax(-webkit-max-content, 1fr) minmax(-webkit-min-content, 400px);
  grid-template-columns: minmax(min-content, 175px) minmax(max-content, 1fr) minmax(min-content, 400px);
  -moz-column-gap: 4rem;
       grid-column-gap: 4rem;
       column-gap: 4rem;
  padding-top: 2.5rem;
}
@media (max-width: 1200px) {
  .app-body {
    grid-template-columns: 1fr;
  }
  .app-body > * {
    margin-bottom: 3.5rem;
  }
}

.app-body-navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .app-body-navigation {
    display: none;
  }
}

.footer {
  margin-top: auto;
}
.footer h1 {
  font-size: 1.5rem;
  line-height: 1.125;
  display: flex;
  align-items: flex-start;
}
.footer h1 small {
  font-size: 0.5em;
  margin-left: 0.25em;
}
.footer div {
  border-top: 1px solid var(--c-gray-600);
  margin-top: 1.5rem;
  padding-top: 1rem;
  font-size: 0.75rem;
  color: var(--c-text-tertiary);
}

.logo {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
  border-bottom: 1px solid var(--c-gray-600);
}
@media (max-width: 1200px) {
  .logo {
    border-bottom: 0;
  }
}

.logo-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.logo-title {
  display: flex;
  flex-direction: column;
  line-height: 1.25;
  margin-left: 0.75rem;
}
.logo-title span:first-child {
  color: var(--c-text-primary);
}
.logo-title span:last-child {
  color: var(--c-text-tertiary);
}

.navigation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--c-text-tertiary);
}
.navigation a {
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: 0.25s ease;
}
.navigation a * {
  transition: 0.25s ease;
}
.navigation a i {
  margin-right: 0.75rem;
  font-size: 1.25em;
  flex-shrink: 0;
}
.navigation a + a {
  margin-top: 1.25rem;
}
.navigation a:hover, .navigation a:focus {
  transform: translateX(4px);
  color: var(--c-text-primary);
}

.tabs {
  display: flex;
  justify-content: space-between;
  color: var(--c-text-tertiary);
  border-bottom: 1px solid var(--c-gray-600);
}
.tabs a {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-decoration: none;
  border-top: 2px solid transparent;
  display: inline-flex;
  transition: 0.25s ease;
}
.tabs a.active, .tabs a:hover, .tabs a:focus {
  color: var(--c-text-primary);
  border-color: var(--c-text-primary);
}

.user-profile {
  display: flex;
  align-items: center;
  border: 0;
  cursor: pointer;
  color: var(--c-text-tertiary);
  transition: 0.25s ease;
}
.user-profile:hover, .user-profile:focus {
  color: var(--c-text-primary);
}
.user-profile:hover span:last-child, .user-profile:focus span:last-child {
  box-shadow: 0 0 0 4px var(--c-gray-800);
}
.user-profile span:first-child {
  display: flex;
  font-size: 1.125rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--c-gray-600);
  font-weight: 300;
}
.user-profile span:last-child {
  transition: 0.25s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 1.5rem;
  flex-shrink: 0;
}

.button {
  width: 32px;
  height: 32px;
  border-radius: 20%;
  border: 0;
  float: right;
  border: 1px solid var(--c-gray-500);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.25s ease;
  flex-shrink: 0;
  padding: 20px;
  margin-left: 10px;
 
}
.icon-button.large {
  width: 42px;
  height: 42px;
  font-size: 1.25em;
}
.icon-button i {
  transition: 0.25s ease;
}
.icon-button:hover, .icon-button:focus {
  background-color: var(--c-gray-600);
  box-shadow: 0 0 0 4px var(--c-gray-800);
}

.tiles {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  -moz-column-gap: 1rem;
       grid-column-gap: 1rem;
       column-gap: 1rem;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  margin-top: 1.25rem;
}
@media (max-width: 700px) {
  .tiles {
    grid-template-columns: repeat(1, 1fr);
  }
}

.tile {
  padding: 1rem;
  border-radius: 8px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  transition: 0.25s ease;
  background-color: #ffffff; /* White background */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow effect */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Add padding for content */

}
.tile:hover {
  transform: translateY(-5px);
}
.tile:focus-within {
  box-shadow: 0 0 0 2px var(--c-gray-800);
}
.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.table tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #ddd;
}

.button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color:  #022D36;
}
.tile a {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
}
.tile a .icon-button {
  color: inherit;
  border-color: inherit;
}
.tile a .icon-button:hover, .tile a .icon-button:focus {
  background-color: transparent;
}
.tile a .icon-button:hover i, .tile a .icon-button:focus i {
  transform: none;
}
.tile a:focus {
  box-shadow: none;
}
.tile a:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.tile-header {
  display: flex;
  align-items: center;
}
.tile-header i {
  font-size: 2.5em;
}
.tile-header h3 {
  display: flex;
  flex-direction: column;
  line-height: 1.375;
  margin-left: 0.5rem;
}
.tile-header h3 span:first-child {
  font-weight: 600;
}
.tile-header h3 span:last-child {
  font-size: 0.825em;
  font-weight: 200;
}

.service-section > h2 {
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
}

.service-section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.service-section-header > * + * {
  margin-left: 1.25rem;
}


.transfer-section {
  margin-top: 2.5rem;
}

.transfer-section-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid var(--c-gray-600);
}
.transfer-section-header h2 {
  font-size: 1.5rem;
}



.filter-options {
  margin-left: 1.25rem;
  padding-left: 1.25rem;
  border-left: 1px solid var(--c-gray-600);
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  float: right;
}
.filter-options p {
  
  font-size: 0.875rem;
}
.filter-options p + * {
  margin-left: auto;
  margin-right: 0.75rem;
}
@media (max-width: 1000px) {
  .filter-options p {
    display: none;
  }
}

.transfers {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}

.transfer {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 0.875rem;
}
@media (max-width: 1000px) {
  .transfer {
    align-items: flex-start;
    flex-direction: column;
  }
}
.transfer + * {
  margin-top: 2rem;
}

.transfer-logo {
  background-color: var(--c-gray-200);
  border-radius: 4px;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.transfer-logo img {
  width: 45%;
}

.transfer-details {
  margin-left: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
}
@media (max-width: 1000px) {
  .transfer-details {
    flex-wrap: wrap;
    margin-left: 0;
    margin-top: 1rem;
  }
}
.transfer-details div {
  width: calc(100% / 3 - 1rem);
}
@media (max-width: 1000px) {
  .transfer-details div {
    width: 100%;
  }
}
.transfer-details div + div {
  margin-left: 1rem;
}
@media (max-width: 1000px) {
  .transfer-details div + div {
    margin-left: 0;
    margin-top: 1rem;
  }
}
.transfer-details dd {
  color: var(--c-text-tertiary);
  margin-top: 2px;
}

.transfer-number {
  margin-left: 2rem;
  font-size: 1.125rem;
  flex-shrink: 0;
  width: 15%;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1000px) {
  .transfer-number {
    margin-left: 0;
    margin-top: 1.25rem;
    width: 100%;
    justify-content: flex-start;
  }
}

.payment-section > h2 {
  font-size: 1.5rem;
}

.payment-section-header {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.payment-section-header p {
  color: var(--c-text-tertiary);
  font-size: 0.875rem;
}
.payment-section-header div {
  padding-left: 1rem;
  margin-left: auto;
  display: flex;
  align-items: center;
}
.payment-section-header div > * + * {
  margin-left: 0.5rem;
}
.mh {
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.pd{
  
  padding-bottom: 10px;
}
.dp{
  float:left;
  margin-left:-20rem;
  
}
.wasswa {
    margin: 0 auto;
    width: 100vh;
    background-color:  white;
    border-radius:0.375rem;
    padding: 4rem;
    margin-top: 7rem; 
    align-items: center;
    
    
  }
  .ha{
    background-color:#f3fafa;
    
  }
.card-button {
  display: flex;
  width: 50px;
  height: 34px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: transparent;
  transition: 0.25s ease;
  border-radius: 4px;
  border: 2px solid var(--c-gray-600);
  color: var(--c-text-primary);
  cursor: pointer;
}
.card-button.mastercard svg {
  max-height: 15px;
}
.card-button:focus, .card-button:hover, .card-button.active {
  color: var(--c-gray-800);
  background-color: var(--c-white);
  border-color: var(--c-white);
}

.faq {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
}
.faq p {
  color: var(--c-text-tertiary);
  font-size: 0.875rem;
}
.faq div {
  margin-top: 0.75rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-top: 1px solid var(--c-gray-600);
  border-bottom: 1px solid var(--c-gray-600);
  font-size: 0.875rem;
  display: flex;
  align-items: center;
}
.faq div label {
  padding-right: 1rem;
  margin-right: 1rem;
  border-right: 1px solid var(--c-gray-600);
}
.faq div input {
  border: 0;
  background-color: transparent;
  padding: 0.25em 0;
  width: 100%;
}

.payment-section-footer {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
}

.save-button {
  border: 1px solid currentColor;
  color: black;
  border-radius: 6px;
  padding: 0.75em 2.5em;
  background-color: transparent;
  transition: 0.25s ease;
  cursor: pointer;
}
.table-container-scrollable {
    overflow-x: auto;
    max-width: 100%;
}

.table {
    width: 100%; /* Ensure table takes up full width */
}
.break-word {
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.table-container-scrollable {
  overflow-x: auto; /* Enable horizontal scrolling */
  max-width: 100%; /* Ensure the container takes up the available width */
}
/* Media query for small devices */
@media screen and (max-width: 768px) {
    .table-container-scrollable {
        overflow-x: scroll; 
        max-width: 350px;
        /* Enable horizontal scrolling */
    }
    
    .mh {
  min-height: 20vh; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.pd{
  
  padding-bottom: 20px;
}
.dp{
 
  margin-left:-9rem;
  
}

.wasswa {
    
    width: 50vh;
    background-color:  white;
    border-radius:0.375rem;
    padding: 1rem;
    margin-top: 7rem; 
    align-items: center;
  }
}
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    z-index: 9999;
    overflow-y: auto;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 85%;
    width: auto;
    overflow-y: auto;
    max-height: 90vh;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 30px;
    color: black;
    font-weight: bold;
}

.modal-content h2 {
    margin-top: 0;
}

.modal-content form {
    display: flex;
    flex-direction: column;
}

.modal-content label {
    margin-top: 10px;
}

.modal-content input,
.modal-content textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.modal-content button {
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #022D36;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
.float{
  float: right;
}
.modal-content button:hover {
    background-color: #0056b3;
}

.tag-scrollers {
	width: 100%;
	max-width: 1200px;
	overflow: hidden;
}

/*	POSSIBLY UPDATE COLORS IN THE GRADIENT
		TO MATCH THE PROJECTS DESIGN SYSTEM
*/
.tag-scroller {
	display: grid;
	grid-gap: 1.5rem;
	gap: 1.5rem;
	-webkit-mask: linear-gradient(90deg, #0000, var(--background) 15%, var(--background) 85%, #0000);
	        mask: linear-gradient(90deg, #0000, var(--background) 15%, var(--background) 85%, #0000);
}

.tag-list {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	/*	IF THE GAP IS CHANGED, DON'T FORGET TO CHANGE 
			THE TRANSFORM VALUE IN THE SCROLL ANIMATION
			AT THE END OF THE STYLING
	*/
	grid-gap: 1.5rem;
	gap: 1.5rem;
}

/*	IF THE USERS PREFERS REDUCED MOTION
		PRESENT THEM WITH A HOROZONTAL LIST
		OF ALL ELEMENTS AND GIVE THEM THE
		OPTION TO MANUALLY SCROLL BY SWIPING
*/
@media (prefers-reduced-motion) {
	.tag-list {
		flex-flow: row nowrap;
		overflow: auto;
		scrollbar-width: none;
		scrollbar-color: transparent transparent;
	}
	.tag-list::-webkit-scrollbar-track {
		background: transparent;
	}
	.tag-list::-webkit-scrollbar-thumb {
		background: transparent;
	}
	.tag-list::-webkit-scrollbar {
		display: none;
		width: 0;
		height: 0;
	}
}
.tag-list li {
	font-family: system-ui;
	font-size: 1.125rem;
	line-height: 1;
	padding: 0.625rem 1.375rem;
	border: 2px solid #000;
	border-radius: 10ch;
	white-space: nowrap;
}


.tag-scroller.scrolling .tag-list {
	width: -webkit-max-content;
	width: max-content;
	flex-wrap: nowrap;
	animation: horizontal-scroll var(--duration) var(--direction, normal) linear infinite;
}

.tag-scroller.scrolling .tag-list:nth-child(even) {
	--direction: reverse;
}

/* PAUSING THE ANIMATION ON HOVER */
.tag-scroller:hover .tag-list {
	animation-play-state: paused;
}

@keyframes horizontal-scroll {
	to {
		/*	0.75rem FOR HALF THE GAP
				OF THE .tag-scroller
		*/
		transform: translateX(calc(-50% - .75rem));
	}
}
.cardy {
  background-color: #fff;

  box-shadow: 2px 2px 5px #9E9E9E, -1px -1px 5px #9E9E9E;
  border-radius: 3px;
  display: grid;
  margin-top:40px;
  
}
.cardy .img-container {
  width: 230px;
 
  grid-column: 2;
  background-size: cover;
  background-position: center center;

    position: relative;
}

.overlayy {
    position: absolute;
    top: 20%;
    left: 10%;
    transform: translate(-15%, -50%);
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    padding: 10px 20px; /* Adjust padding as needed */
}
.img-text{
  color: black;
  background-color: white;
  
  margin-top: 20px;
  width: 70%;
  
}
.img-text p{
  margin-top: 2rem;
  padding: 10px;
  
}

.card-content {
  grid-column: 3 / 5;
  padding: 10px 30px;
  border-left: 1px solid #ccc;
}
.h2 {
  text-transform: uppercase;
  color: #555;
}
h1 {
  margin-bottom: 0;
}
.card-content .author {
  border-top: 1px solid #cdcdcd;
  font-weight: 700;
  margin-top: 25px;
  padding: 25px 0 10px 0;
  color: #555;
}

@media only screen and (max-width:600px) {
  .cardy {
    display: block;
    height: 740px;
  }
  .cardy .img-container {
    height: 170px;
    width: 100%;
  }
  .card-content {
    border: 0;
    border-top: 1px solid #ccc;
  }
  
  .overlayy {
    position: absolute;
    top: 20%;
    left: 7%;
    transform: translate(-15%, -50%);
}
}

.pen-title {
  color: #FFFFFF;
  margin-bottom: 0;
  text-align: center;
  text-shadow: 1px 1px 0 #000;
}

.pen-description {
  color: #FFFFFF;
  margin-bottom: 3rem;
  text-align: center;
  text-shadow: 1px 1px 0 #000;
}
.pen-description a {
  color: #FFFFFF;
  text-decoration: underline;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

img {
  height: auto;
  max-width: 100%;
}

.wrap {
  max-width: 85.375rem;
  
}

.image-as-background {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.screen-reader-text {
  height: auto;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
}
.screen-reader-text:hover, .screen-reader-text:active, .screen-reader-text:focus {
  height: auto;
  width: auto;
  background-color: #000000;
  border-radius: 2px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #FFFFFF;
  display: block;
  font-size: 0.8rem;
  font-weight: 700;
  left: 0.3125rem;
  line-height: normal;
  padding: 0.9375rem 1.4375rem 0.875rem;
  text-decoration: none;
  top: 0.3125rem;
  z-index: 100000;
}

.logozo {
  padding: 10px;
 
}

.logo-slider {
  
   
    background-color: #fff;
}

.logos-slide {
    display: inline-block;
    
}

.logos-slide img {
    width: 60px;
    height: 43px;
    float: left;
    
    background-color: none;
}



.listBtnDropdown {
  display: flex;
  flex-direction: row;
  align-items: end;
  max-height: 5px;
  margin:0 auto;
}

.btnDropdown {
  flex: 1 1 auto;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.btnNav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-gap: 2px;
  gap: 2px;
  height: 30px;
  width: 100%;
  padding: 0 16px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  color: white!important;
}

.btnNav .chevron {
  font-size: 8px;
  opacity: 0.2;
  transition: all 340ms;
  color: black!important;
}

.dropdown {
  display: grid;
  width: calc(100% - 8px);
  grid-template-rows: 0fr;
  background: #f6f6f6;
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  border-top: 1px solid #e4e4e4;
  border-radius: 16px 16px 0 0;
  opacity: 0;
  transition: all 340ms;
}

.dropdown > .dropdown-contenty {
  overflow: hidden;
  opacity: 0;
  transition: all 340ms;
}

.btnDropdown.first .btnNav { 
  border-radius: 8px 0 0 8px; 
  background: #022d36;
}

.btnDropdown.two .btnNav { 
  background: #ff9333;
}

.btnDropdown.three .btnNav { 
  border-radius: 0 8px 8px 0; 
  background: #ff3633;
}

.btnDropdown:hover .dropdown {
  grid-template-rows: 1fr;
  opacity: 1;
}

.btnDropdown:hover .chevron {
  transform: rotate(180deg);
  transition: all 340ms;
  opacity: 1;
}

.btnDropdown:hover .dropdown .dropdown-contenty {
  opacity: 1;
  transition: all 1s;
}

.dropdown-contenty ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.dropdown-contenty li {
  padding: 6px 8px;
  font-size: 13px;
  border-bottom: 1px solid #d7d7d7;
}
.but{
  
  padding: 20px;
}
.cmo{
  float: right;
  font-size: 20px;
  color: green;
}

*{
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
}

:root{
    --pinkish-red: #C35A74;
    --medium-blue: #ff3633;
    --greenish-blue: #022d36;
    --bright-orange: #ff9333;
    --white-smoke: #F5F5F4;
    --white: #FFF;
    --dark-gray: #7D7C7C;
    --black: #000;
}



.content-jvw{
    display: flex;
   
    justify-content: center;
    align-content: center;
   
}

.box-jvw{
    display: flex;
    flex-direction: column;
    height: 486px;
    width: 250px;
    margin-left: 20px;
    border-radius: 20px;
    background: var(--white);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 20%);
   
}

.title-jvw{
    width: 100%;
    padding: 10px 0;
    font-size: 1.2em;
    font-weight: lighter;
    text-align: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    color: var(--white-smoke);
}

.basic .title-jvw{
    background: var(--bright-orange);
}

.standard .title-jvw{
    background: var(--medium-blue);
}

.business .title-jvw{
    background: var(--greenish-blue);
}

.view{
    display: block;
    width: 100%;
    padding: 30px 0 20px;

    background: var(--white-smoke);
}

.icon-jvw{
    display: flex;
    justify-content: center;
}

.icon-jvw img{
    width: 40px;
}

.cost{
    display: flex;
    justify-content:center;
    flex-direction: row;
    margin-top: 10px;
    font-size: 10px;
}

.amount{
    font-size: 2.8em;
    font-weight: bolder;
}

.detail{
    margin: auto 0 auto 5px;
    width: 70px;
    font-size: 0.7em;
    font-weight: bold;
    line-height: 15px;
    color: #7D7C7C;
}

.description{
    margin: 10px auto;
    font-size: 0.8em;
    color: #7D7C7C;
}

ul{
    list-style: none;
}

li{
    margin-top: 10px;
}

.description li::before{
    content: "";
    background-image: url("https://i.postimg.cc/ht7g996V/check.png");
    background-position: center;
    background-size: cover;
    opacity: 0.5;

    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
}

.button-jv{
    margin: 0 auto 30px;
}

.button-jv button{
    height: 40px;
    width: 150px;
    font-size: 0.7em;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #7D7C7C;
    border: 2px solid var(--dark-gray);
    border-radius: 50px;

    background: transparent;
}

.txt{
  text-align: center;
  margin-top: 10px;
}
.button-jv button:hover{
    color: var(--white-smoke);
    transition: 0.5s;
    border: none;

    background: var(--bright-orange);
}

/* Responsiveness:Start */
@media screen and (max-width:970px) {
    .content-jvw{
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 50px auto;
    }
    .standard, .business{
        margin-top: 25px;
    }
}
.fas{
  
  padding: 20px;
  
}


