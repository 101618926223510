
:root {
  --body-font: "Inter", sans-serif;
  --theme-bg: #f5f9fb;
  --body-color: #808191;
  --button-bg: #022D36;
  --border-color: rgb(128 129 145 / 24%);
  --video-bg: #022D36;
  --delay: 0s;
}


.containeri {
  background-color: var(--theme-bg);
 
  height: 95vh;
  display: flex;
  overflow: scroll;
  width: 100%;
  border-radius: 20px;
  font-size: may15px;
  font-weight: 500;
  
  position: relative;
}
/* Custom scrollbar styling for WebKit browsers */
.containeri::-webkit-scrollbar {
  width: 16px; /* Increase scrollbar width */
}

.containeri::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded corners for thumb */
}

.containeri::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Scrollbar thumb color on hover */
}

.containeri::-webkit-scrollbar-track {
  background: #f1f1f1; /* Scrollbar track color */
}

.sidebar {
  width: 220px;
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  transition-duration: 0.2s;
  overflow-y: auto;
  overflow-x: hidden;
}
.sidebar .lowgo {
  display: none;
  width: 30px;
  height: 30px;
  background-color: #22b07d;
  flex-shrink: 0;
  color: #fff;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
}
.sidebar .logo-expand {
  text-decoration: none;
  color: #fff;
  font-size: 19px;
  font-weight: 600;
  line-height: 34px;
  position: sticky;
  top: 0;
}
.sidebar .logo-expand:before {
  content: "";
  position: absolute;
  top: -30px;
  left: 0;
  background: var(--theme-bg);
  width: 200px;
  height: 70px;
  z-index: -1;
}
.sidebar-link:hover, .sidebar-link.is-active {
  color: #fff;
  font-weight: 600;
}
.sidebar-link:hover:nth-child(2n+1) svg, .sidebar-link.is-active:nth-child(2n+1) svg {
  background: #ff7551;
}
.sidebar-link:hover:nth-child(2n) svg, .sidebar-link.is-active:nth-child(2n) svg {
  background: #32a7e2;
}
.sidebar-link:hover:nth-child(2n+3) svg, .sidebar-link.is-active:nth-child(2n+3) svg {
  background: #6c5ecf;
}
.sidebar.collapse {
  width: 90px;
  border-right: 1px solid var(--border-color);
}
.sidebar.collapse .logo-expand,
.sidebar.collapse .side-title {
  display: none;
}
.sidebar.collapse .lowgo {
  display: flex;
}
.sidebar.collapse .side-wrapper {
  width: 30px;
}
.sidebar.collapse .side-menu svg {
  margin-right: 30px;
}

@-webkit-keyframes bottom {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes bottom {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.side-menu {
  display: flex;
  flex-direction: column;
}
.side-menu a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--body-color);
}
.side-menu a + a {
  margin-top: 26px;
}
.side-menu svg {
  width: 30px;
  padding: 8px;
  border-radius: 10px;
  background-color: var(--button-bg);
  flex-shrink: 0;
  margin-right: 16px;
  fill: white;
}
.side-menu svg:hover {
  color: #fff;
}

.side-title {
  font-size: 12px;
  letter-spacing: 0.07em;
  margin-bottom: 24px;
}

.side-wrapper {
  border-bottom: 1px solid var(--border-color);
  padding: 36px 0;
  width: 145px;
}
.side-wrapper + .side-wrapper {
  border-bottom: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-grow: 1;
}
.dpz{
   height: 20px;
   width:50px ; 
  
}
.header {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 30px;
}

.search-bar {
  height: 34px;
  display: flex;
  width: 100%;
  max-width: 450px;
}
.search-bar input {
  width: 100%;
  height: 100%;
  border: none;
  background-color: var(--button-bg);
  border-radius: 8px;
  font-family: var(--body-font);
  font-size: 14px;
  font-weight: 500;
  padding: 0 40px 0 16px;
  box-shadow: 0 0 0 2px rgba(134, 140, 160, 0.02);
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.966 56.966' fill='%23717790c7'%3e%3cpath d='M55.146 51.887L41.588 37.786A22.926 22.926 0 0046.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 00.083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z'/%3e%3c/svg%3e");
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: 96%;
  color: #fff;
}

.user-settings {
  display: flex;
  align-items: center;
  padding-left: 20px;
  flex-shrink: 0;
  margin-left: auto;
}
.user-settings svg {
  width: 10px;
  flex-shrink: 0;
}
@media screen and (max-width: 575px) {
  .user-settings svg {
    display: none;
  }
}
.user-settings .notify {
  position: relative;
}
.user-settings .notify svg {
  width: 20px;
  margin-left: 24px;
  flex-shrink: 0;
}
.user-settings .notify .notification {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ec5252;
  position: absolute;
  right: 1px;
  border: 1px solid var(--theme-bg);
  top: -2px;
}
@media screen and (max-width: 575px) {
  .user-settings .notify .notification {
    display: none;
  }
}
.user-img {
  width: 100px;
  
  flex-shrink: 0;
  -o-object-fit: cover;
     object-fit: cover;

}
.user-name {
  color: black;
  font-size: 30px;
margin-right:40px;
margin-top:13px;
  font-weight: bold;
}
@media screen and (max-width: 575px) {
  .user-name {
    display: none;
  }
}

.main-container {
  display: flex;
  flex-direction: column;
  padding: 0 30px 30px;
  flex-grow: 1;
  overflow: auto;
}

.anim {
  -webkit-animation: bottom 0.8s var(--delay) both;
          animation: bottom 0.8s var(--delay) both;
}

.main-header {
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  padding-bottom: 20px;
  position: sticky;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, #1f1d2b 0%, #1f1d2b 78%, rgba(31, 29, 43, 0) 100%);
  z-index: 11;
}

.small-header {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  margin: 30px 0 20px;
}

.main-blogs {
  display: flex;
  align-items: center;
}
.main-blog__author {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}
.main-blog__author.tips {
  flex-direction: column-reverse;
  align-items: flex-start;
}
.main-blog__title {
  font-size: 25px;
  max-width: 12ch;
  font-weight: 600;
  letter-spacing: 1px;
  color: #fff;
  margin-bottom: 30px;
}
.main-blog {
  background-image: url("https://assets.codepen.io/3364143/skate-removebg-preview.png");
  background-size: 80%;
  background-position-x: 150px;
  background-color: #31abbd;
  display: flex;
  flex-direction: column;
  width: 65%;
  padding: 30px;
  border-radius: 20px;
  align-self: stretch;
  overflow: hidden;
  position: relative;
  transition: background 0.3s;
  background-repeat: no-repeat;
}
.main-blog + .main-blog {
  margin-left: 20px;
  width: 35%;
  background-image: url(https://c0.anyrgb.com/images/1020/945/venice-beach-2018-outdoors-sport-men-jumping-desert-sunset-extreme-sports-one-person-action.jpg);
  background-color: unset;
  background-position-x: 0;
  background-size: 139%;
  filter: saturate(1.4);
}
.main-blog + .main-blog .author-img {
  border-color: rgba(255, 255, 255, 0.75);
  margin-top: 14px;
}
.main-blog + .main-blog .author-img__wrapper svg {
  border-color: #ffe6b2;
  color: #e7bb7d;
}
.main-blog + .main-blog .author-detail {
  margin-left: 0;
}
@media screen and (max-width: 905px) {
  .main-blog, .main-blog + .main-blog {
    width: 50%;
    padding: 30px;
  }
  .main-blog {
    background-size: cover;
    background-position-x: center;
    background-blend-mode: overlay;
  }
}
.main-blog__time {
  background: rgba(21, 13, 13, 0.44);
  color: #fff;
  padding: 3px 8px;
  font-size: 12px;
  border-radius: 6px;
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.author-img {
  width: 52px;
  height: 52px;
  border: 1px solid rgba(255, 255, 255, 0.75);
  padding: 4px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.author-img__wrapper {
  position: relative;
  flex-shrink: 0;
}
.author-img__wrapper svg {
  width: 16px;
  padding: 2px;
  background-color: #fff;
  color: #0daabc;
  border-radius: 50%;
  border: 2px solid #0daabc;
  position: absolute;
  bottom: 5px;
  right: 0;
}
.author-name {
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 8px;
}
.author-info {
  font-size: 13px;
  font-weight: 400;
  color: #fff; 
}
.author-detail {
  margin-left: 16px;
}

.seperate {
  width: 3px;
  height: 3px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 6px;
}
.seperate.video-seperate {
  background-color: var(--body-color);
}

.videozi{
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
@media screen and (max-width: 980px) {
  .videozi {
    grid-template-columns: repeat(2, 1fr);
  }
}

.vidiyo {
  position: relative;
  background-color: var(--video-bg);
  border-radius: 20px;
  overflow: hidden;
  transition: 0.4s;
}
.video-wrapper {
  position: relative;
}
.video-name {
  color: #fff;
  font-size: 20px;
  line-height: 1.4em;
  padding: 12px 20px 0;
  overflow: hidden;
  background-color: var(--video-bg);
  z-index: 9;
  position: relative;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.video-view {
  font-size: 12px;
  padding: 12px 20px 20px;
  background-color: var(--video-bg);
  position: relative;
  color:#fff;
}
.video-by {
  transition: 0.3s;
  padding: 20px 20px 0px;
  display: inline-flex;
  position: relative;
  color:#fff;
  font-size:20px;
  font-weight:bold;
}
.video-by:before {
  content: "";
  background-color: #22b07d;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  top: 26px;
  right: 5px;
}
.video-by.offline:before {
  background-color: #ff7551;
}
.video-time {
  position: absolute;
  background: rgba(21, 13, 13, 0.44);
  color: rgba(255, 255, 255, 0.85);
  padding: 3px 8px;
  font-size: 12px;
  border-radius: 6px;
  top: 10px;
  z-index: 1;
  right: 8px;
}
.video:hover video {
  transform: scale(1.6);
  transform-origin: center;
}
.video:hover .video-time {
  display: none;
}
.video:hover .video-author {
  bottom: -65px;
  transform: scale(0.6);
  right: -3px;
  z-index: 10;
}
.video:hover .video-by {
  opacity: 0;
}
.video-author {
  position: absolute;
  right: 10px;
  transition: 0.4s;
  bottom: -25px;
}
.video-author svg {
  background-color: #0aa0f7;
  color: #fff;
  border-color: var(--video-bg);
}

video {
  max-width: 100%;
  width: 100%;
  border-radius: 20px 20px 0 0;
  display: block;
  cursor: pointer;
  transition: 0.4s;
}

.stream-area {
  display: none;
}
@media screen and (max-width: 940px) {
  .stream-area {
    flex-direction: column;
  }
  .stream-area .video-stream {
    width: 100%;
  }
  .stream-area .chat-stream {
    margin-left: 0;
    margin-top: 30px;
  }
  .stream-area .video-js.vjs-fluid {
    min-height: 250px;
  }
  .stream-area .msg__content {
    max-width: 100%;
  }
}

.show .stream-area {
  display: flex;
}
.show .main-header,
.show .main-blogs,
.show .small-header,
.show .videozi {
  display: none;
}

.video-stream {
  width: 65%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: 0.3s;
}
.video-stream:hover .video-js .vjs-big-play-button {
  opacity: 1;
}

.video-p {
  margin-right: 12px;
  -o-object-fit: cover;
     object-fit: cover;
  flex-shrink: 0;
  border-radius: 50%;
  position: relative;
  top: 0;
  left: 0;
}
.video-p .author-img {
  border: 0;
}
.video-p-wrapper {
  display: flex;
  align-items: center;
}
.video-p-wrapper .author-img {
  border: 0;
}
.video-p-wrapper svg {
  width: 20px;
  padding: 4px;
}
@media screen and (max-width: 650px) {
  .video-p-wrapper {
    flex-direction: column;
  }
  .video-p-wrapper .button-wrapper {
    margin: 20px auto 0;
  }
  .video-p-wrapper .video-p-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .video-p-wrapper .video-p {
    margin-right: 0;
  }
}
.video-p-sub {
  font-size: 12px;
}
.video-p-title {
  font-size: 24px;
  color: #fff;
  line-height: 1.4em;
  margin: 16px 0 20px;
}
.video-p-subtitle {
  font-size: 14px;
  line-height: 1.5em;
  max-width: 60ch;
}
.video-p-subtitle + .video-p-subtitle {
  margin-top: 16px;
}
.video-p-name {
  margin-bottom: 8px;
  color: #fff;
  display: flex;
  align-items: center;
}
.video-p-name:after {
  content: "";
  width: 6px;
  height: 6px;
  background-color: #22b07d;
  border-radius: 50%;
  margin-left: 8px;
  display: inline-block;
}
.video-p-name.offline:after {
  background-color: #ff7551;
}

.video-content {
  width: 100%;
}

.button-wrapper {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.like {
  display: flex;
  align-items: center;
  background-color: var(--button-bg);
  color: #fff;
  border: 0;
  font-family: var(--body-font);
  border-radius: 8px;
  padding: 10px 16px;
  font-size: 14px;
  cursor: pointer;
}
.like.red {
  background-color: #ea5f5f;
}
.like svg {
  width: 18px;
  flex-shrink: 0;
  margin-right: 10px;
  padding: 0;
}
.like + .like {
  margin-left: 16px;
}

.video-stats {
  margin-left: 30px;
}

.video-detail {
  display: flex;
  margin-top: 30px;
  width: 100%;
}

.chat-header {
  display: flex;
  align-items: center;
  padding: 20px 0;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  position: sticky;
  top: 0;
  background-color: #;
  left: 0;
  z-index: 1;
  border-bottom: 1px solid var(--border-color);
}
.chat-header svg {
  width: 15px;
  margin-right: 6px;
  flex-shrink: 0;
}
.chat-header span {
  margin-left: auto;
  color: var(--body-color);
  font-size: 12px;
  display: flex;
  align-items: center;
}

.chat-stream {
  flex-grow: 1;
 
}

.chat {
  background-color: #022D36;
  border-radius: 20px;
  padding: 0 20px;
  width: 30rem;
  max-height: 414px;
  overflow: auto;
}
@media only screen and (max-width: 767px) {
  .chat {
    width: calc(100% - 10px); /* Adjust the subtraction value as needed */
    max-width: none;
  }
}
.chat-footer {
  display: flex;
  align-items: center;
  position: sticky;
  bottom: 0;
  left: 0;
  width: calc(100% + 20px);
  padding-bottom: 12px;
  background-color: #022D36;
}
.chat-footer input {
  width: 100%;
  border: 0;
  background-color: #2d303e;
  border-radius: 20px;
  font-size: 12px;
  color: #fff;
  margin-left: -10px;
  padding: 12px 40px;
  font-weight: 500;
  font-family: var(--body-font);

}
.chat-footer input::-moz-placeholder {
  color: #6c6e78;
}
.chat-footer input:-ms-input-placeholder {
  color: #6c6e78;
}
.chat-footer input::placeholder {
  color: #6c6e78;
}
.chat-footer:before {
  content: "";
  position: absolute;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M21.435 2.582a1.933 1.933 0 00-1.93-.503L3.408 6.759a1.92 1.92 0 00-1.384 1.522c-.142.75.355 1.704 1.003 2.102l5.033 3.094a1.304 1.304 0 001.61-.194l5.763-5.799a.734.734 0 011.06 0c.29.292.29.765 0 1.067l-5.773 5.8c-.428.43-.508 1.1-.193 1.62l3.075 5.083c.36.604.98.946 1.66.946.08 0 .17 0 .251-.01.78-.1 1.4-.634 1.63-1.39l4.773-16.075c.21-.685.02-1.43-.48-1.943z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: center;
  width: 18px;
  height: 18px;
  background-color: #6c5ecf;
  padding: 4px;
  border-radius: 50%;
  right: 16px;
}
.chat-vid__title {
  color: #fff;
  font-size: 18px;
}
.chat-vid__container {
  margin-top: 40px;
}
.chat-vid__wrapper {
  display: flex;
  align-items: center;
  margin-top: 26px;
}
.chat-vid__name {
  color: #fff;
  font-size: 14px;
  line-height: 1.3em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
}
.chat-vid__img {
  width: 100px;
  height: 80px;
  border-radius: 10px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: right;
     object-position: right;
  margin-right: 16px;
  transition: 0.3s;
}
.chat-vid__img:hover {
  transform: scale(1.02);
}
.chat-vid__content {
  max-width: 20ch;
}
.chat-vid__by, .chat-vid__info {
  color: var(--body-color);
  font-size: 13px;
}
.chat-vid__by {
  margin: 6px 0;
}
.chat-vid__button {
  background-color: #6c5ecf;
  border: 0;
  color: #fff;
  font-size: 13px;
  margin-top: 26px;
  display: flex;
  padding: 0 10px;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;
}
.chat-vid__button:hover {
  background-color: #5847d0;
}

.message {
  display: flex;
  align-items: center;
  margin-top: 18px;
}
.message:last-child {
  margin-bottom: 18px;
}
.message-container .author-img__wrapper svg {
  width: 15px;
}

.msg__name {
  font-size: 20px;
  color:#fff;
}
.msg__content {
  line-height: 1.4em;
  max-width: 26ch;
  color:#fff;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.video-js .vjs-control-bar {
  display: flex;
  align-items: center;
}

.vjs-poster {
  background-size: 150%;
}

.video-js .vjs-control-bar {
  width: 100%;
  position: absolute;
  bottom: 14px;
  padding-left: 36px;
  left: 14px;
  width: calc(100% - 28px);
  right: 0;
  border-radius: 10px;
  height: 4em;
  background-color: #2b333f;
  background-color: rgba(43, 51, 63, 0.7);
}
@media screen and (max-width: 625px) {
  .video-js .vjs-control-bar {
    padding-left: 0;
  }
}

.video-js:hover .vjs-big-play-button {
  background-color: rgba(43, 51, 63, 0.5);
}

.video-js .vjs-big-play-button {
  transition: 0.3s;
  opacity: 0;
  border: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-js .vjs-big-play-button:hover {
  background-color: rgba(43, 51, 63, 0.7);
  border-color: transparent;
}

.vjs-play-control:after {
  content: "LIVE";
  position: absolute;
  left: -66px;
  top: 7px;
  background-color: #8941e3;
  height: 24px;
  font-family: var(--body-font);
  font-size: 10px;
  padding: 0 12px 0 26px;
  display: flex;
  font-weight: 700;
  letter-spacing: 0.03em;
  align-items: center;
  border-radius: 6px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='%23fff' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-circle'%3e%3ccircle cx='12' cy='12' r='10'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 6px;
  background-position: 12px;
}
@media screen and (max-width: 625px) {
  .vjs-play-control:after {
    display: none;
  }
}

.vjs-menu-button-inline .vjs-menu {
  top: 4px;
}

.video-js .vjs-control:before,
.video-js .vjs-time-control {
  line-height: 40px;
}

.video-js .vjs-tech {
  -o-object-fit: cover;
     object-fit: cover;
}

button.vjs-play-control.vjs-control.vjs-button {
  margin-left: 40px;
}
@media screen and (max-width: 625px) {
  button.vjs-play-control.vjs-control.vjs-button {
    margin-left: 0;
  }
}

.vjs-icon-fullscreen-enter:before,
.video-js .vjs-fullscreen-control:before {
  content: "";
  position: absolute;
  display: block;
  
  background-size: 11px;
  background-position: center;
  background-position-y: 14px;
  background-repeat: no-repeat;
  opacity: 0.6;
}

.vjs-playback-rate .vjs-playback-rate-value {
  font-size: 1.1em;
  line-height: 3.5em;
  opacity: 0.6;
  font-weight: 700;
  font-family: var(--body-font);
}

.video-js .vjs-playback-rate {
  width: 2.2em;
}

.video-js.vjs-fluid {
  border-radius: 20px;
  overflow: hidden;
  min-height: 414px;
}

@media screen and (max-width: 735px) {
  .main-blogs {
    flex-wrap: wrap;
  }

  .main-blog,
.main-blog + .main-blog {
    width: 100%;
  }

  .videozi {
    grid-template-columns: 1fr;
  }

  .main-blog + .main-blog {
    margin-left: 0;
    margin-top: 20px;
    background-size: cover;
  }
}
@media screen and (max-width: 475px) {
  .main-blog__title {
    font-size: 20px;
  }

  .author-name {
    font-size: 14px;
  }

  .main-blog__author {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .author-detail {
    margin-left: 0;
  }

  .main-blog .author-img {
    margin-top: 14px;
  }

  .main-container {
    padding: 0 20px 20px;
  }

  .header {
    padding: 20px;
  }

  .sidebar.collapse {
    width: 40px;
  }

  .sidebar {
    align-items: center;
  }



  .container {
    height: auto;
    border-radius: 0;
    max-height: 100%;
  }
}
::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(21, 20, 26, 0.63);
  border-radius: 10px;
}


  .viannie {
    display: flex;
    flex-wrap: wrap;
    max-width: 100;
   
  }

main {
  width: min(980px, 95%);
 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  
}
main .heada{
  margin-bottom: 55px;
}
@media screen and (max-width: 600px) {
  main .heada{
    margin-bottom: 70px !important;
  }
}
main .heada h1 {
  font-weight: 800;
  font-size: 25px;
  margin: 5px -5px 15px;
}
main .heada span {
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  color: #D0312D;
}
main .heada p {
 
  line-height: 1.6;
  color: #565656;
}
main .item {
 
  height: 300px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  width: calc(33.33% - 10px);
    margin-bottom: 20px;
    margin-left: 10px;
}
main .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  inset: 0;
}
main .item .overlayzi {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 28px 25px;
  color: #fff;
  background: #020024;
  background: linear-gradient(0deg, #020024 0%, #000032a1 3%, #17d9ff00 100%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
main .item .overlayzi span {
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 60px;
  padding: 10px 20px;
  display: inline-block;
}
main .item .overlayzi > div h2 {
 
 text-align: left;
  margin-bottom: 5px;

}

.owl-nav {
  position: absolute;
  top: -100px;
  right: 0;
}

@media (max-width: 1024px) {
    .item {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    .viannie {
      flex-direction: column;
      align-items: center;
      
    }

  main .item {
      width: 100%; 
      margin-left: -20px;
    }
  }