
@tailwind base;
@tailwind components;
@tailwind utilities;
 html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    box-sizing: border-box;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  a {
    text-decoration: none!important;
    color: #022D36;
  }
  a:hover {
    color: #D0312D;
  }

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important; /* Change the color to match your background */
}
  body {
    background: #fff;
    color: #0B093B;
    font-family: 'Khula', sans-serif;
    overflow-x: hidden;
    font-size: 16px;
    font-weight: 400;
  }
  .container {
    width: 100%;
    max-width: 1220px;
    margin: 0 auto;
    padding: 0px 30px;
  }
  .padding{
   padding: 20px; 

    
  }
  @media only screen and (max-width: 859px) {
    .container {
      padding: 0 15px;
    }
  }
  .regular {
    font-weight: 400!important;
  }
  .semiBold {
    font-weight: 600!important;
  }
  .extraBold {
    font-weight: 700!important;
  }


.overlayz{
 
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9) !important; /* Overlay background color with transparency */
  color: white;
  text-align: center;
  padding: 5px;
  box-sizing: border-box;
}
  /* HELLPERS */
  .textCenter {
    text-align: center
  }
  .align {
    
    text-align: justify;
  }
  .animate {
    transition: 0.3s ease;
  }
  .pointer {
    cursor: pointer;
  }
  .relative {
    position: relative;
  }
  .textCenter {
    text-align: center;
  }
  .textLeft {
    text-align: left;
  }
  .textRight {
    text-align: right;
  }
  .x100 {
    width: 100%;
  }
  .red{
    color:#D0312D ;
  }
  .blue{
    color:blue;
  }
  .active {
    border-bottom: 2px solid #D0312D;
    color: #D0312D;
  }
  .tag {
    padding: 5px 10px;
    margin: 10px 0;
  }
  .shadow {
    box-shadow: 0 0 11px rgba(237, 232, 237, 0.5)  ; 
    -webkit-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5)  ; 
    -moz-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5)  ; 
  }
  /* FLEXBOX */
  .flex {
    display: flex;
  }
  .flexSpaceCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flexNullCenter {
    display: flex;
    align-items: center;
    
  }
  .flexColumn {
    flex-direction: column;
  }
  .flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .viannie{
   text-align: justify;
  }
  .flexSpaceNull {
    display: flex;
    justify-content: space-between;
  }
.leftor{
  
  align-items: left;
  text-align: left;
  list-style-type: disc;
  
}
.leftor li{
  
  padding: 5px;
}
  /* FONT SIZES */
  .font11 {
    font-size: 0.688rem;
  }
  .font12 {
    font-size: 0.75rem;
  }
  .font13 {
    font-size: 0.813rem;
  }
  .font14 {
    font-size: 0.875rem;
  }
  .font15 {
    font-size: 0.938rem;
  }
  .font18 {
    font-size: 1.125rem;
  }
  .font20 {
    font-size: 1.25rem;
  }
  .font25 {
    font-size: 1.563rem;
  }
  .font30 {
    font-size: 1.875rem;
  }
  .font40 {
    font-size: 2.5rem;
  }
  .font60 {
    font-size: 3.75rem;
    line-height: 4.5rem;
  }
  @media only screen and (max-width: 859px) {
    .font11 {
      font-size: 0.688rem;
    }
    .font12 {
      font-size: 0.688rem;
    }
    .font13 {
      font-size: 0.75rem;
    }
    .font14 {
      font-size: 0.813rem;
    }
    .font15 {
      font-size: 0.813rem;
    }
    .font18 {
      font-size: 0.938rem;
    }
    .font20 {
      font-size: 1.125rem;
    }
    .font25 {
      font-size: 1.25rem;
    }
    .font30 {
      font-size: 1.5rem;
    }
    .font40 {
      font-size: 1.875rem;
    }
    .font60 {
      font-size: 2.8rem;
    }
    
  }
  
  .margin {
  display: none; /* Hide by default */
}
.Button1{
  display:none;
 
}
@media only screen and (max-width: 768px) {
  .Button1 {
    display: none; /* hide on mobile */
  }
}

@media only screen and (min-width: 769px) {
  .Button1 {
    display: none; /* hide on tablets */
  }
}
/* Media query for small devices (up to 768px width) */
@media only screen and (max-width: 768px) {
  .margin {
    display: flex; /* Show on small devices */
    margin-top: 10px;
    height: 45%;
    position: absolute;
  }
}
  /* BORDER RADIUS */

  .radius8 {
    border-radius: 1rem; 
  }
  .radius6 {
    border-radius: 0.6rem;
  }
  /* COLORS */
  .darkColor {
    color: #022D36;
  }
  .cc{
        color: #D0312D;
  }
  .purpleColor {
    color: #D0312D;
  }
  .orangeColor {
    color: #F2B300;
  }
  .lightColor {
    color: #F5F5F5;
  }
  .coralColor {
    color: #C4FFFF;
  }
  .greenColor {
    color: #49CB86;
  }
  .greyColor {
    color: #707070;
  }
  .whiteColor {
    color: #fff;
  }
  /* BACKGROUNDS */
  .darkBg {
    background-color: #022D36;
  }
  
  .liBg{
    
    background-color: #ececec;
  }
  .purpleBg {
    background-color: #D0312D;
  }
  .orangeBg {
    background-color: #F2B300;
  }
  .lightBg {
    background-color: #F5F5F5;
  }
  .hardBg{
    
    background-color: #022D36;
 
  }
  .coralBg {
    background-color: #C4FFFF;
  }
  .greenBg {
    background-color: #49CB86;
  }
  .greyBg {
    background-color: #707070;
  }
  .whiteBg {
    background-color: #fff;
  }



/******* Search Bar css****/

/* SearchBar.css */
.search-input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  width: 150px;
  box-sizing: border-box;
}


.search-container {
  position: relative; /* Make the container a positioning context */
}

.search-suggestions {
  position: absolute;
  top: 100%; /* Position the suggestions below the search input */
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: calc(100% - 2px); /* Adjust width to match the container's width */
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.suggestion-item {
  padding: 8px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f5f5f5;
}
/* SearchBar.css */
.search-container {
  display: flex;
  align-items: center; /* Align items vertically */
}

.search-input {
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px; /* Adjust border-radius */
  padding: 8px;

  box-sizing: border-box;
}

.search-button {
  background-color: #D0312D;
  color: #fff;
  border: none;
  border-radius: 0 4px 4px 0;
  padding: 8px 16px;
  cursor: pointer;
  
}
/* Community.css */

.first-text {
  font-weight: light;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}


.text-steps-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.second-text {
  font-size: 20px;
  font-weight: medium;
  color: rgba(0, 0, 0, 0.8);
  display: inline-block;
}

.steps-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.step {
  display: flex;
  align-items: center;
  gap: 5px;
}
.floater{

  float: left;
 margin-left: -30px;
}

.step-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 15px;
  color: #fff;
}

.step-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spin {
  animation: spin 10s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bo{
  border: 1px solid #D0312D;
  background-color: #D0312D;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  border-radius: 20px;
}
  .red{
    
    color: red;
  }
  .green{
    
    color: green ;
    
  }
  

.card {
    width: 288px;
    height: auto;
    background-color: white;
    border-radius: 1rem;
    
    box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-bottom: 10px; /* Adjust as needed */
    margin-left: auto; /* Center horizontally */
    margin-right: auto; /* Center horizontally */
}

figure {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    position: relative;
}

figure img {
    width: 100%;
    height: 80%;
    object-fit: cover;
}

figure img:hover {
    transition: all 0.6s ease-in-out;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.icons svg {
    fill: white;
    
}


.btnAddNone{
    display: none;
}

.btnAdd span {
    color: white;
}

.btnAdd:hover {
    background-color: gray;
    
}



.descriptionCard {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
  
}

.informationCard1 {
    align-items: center;
  
    border-bottom: 1px solid #D3D3D3;
}


.shortInformation {
    padding-right: 30px;
}

.shortInformation h3 {
    font-size: 16px;
    font-weight: 700;

}

.shortInformation p {
    font-size: 12px;
    color: #A9A9A9;
}
.state{
   color:green;
  padding: 10px;
  
}
.btnComprar {
    height: 32px;
    font-size: 10px;
    background-color: #022D36;
    border-radius: 100px;
    border: none;
}

.btnComprar:hover {
    cursor: pointer;
    background-color: #022D36;
}

.btnComprar span {
    color: white;
    margin-left: 26px;
    margin-right: 26px;
    font-weight: 500;
}

.priceDescription {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}
.marketcount{
    position: relative;
    padding-right: 5px;
}


.priceDescription p {
    font-size: 16px;
    font-weight: 600;
    color: #001547;
}
.svg {

  height: 30px;
  width: 30px;
}
.location-status-container {
  display: flex;
  align-items: center;
}

.location-container{
  display: flex;
  
 
}

.status-container img {
  
  width: 20px;
  height: 20px;
}


.showcase {
 
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.travel-card {
  position: relative;
  display: inline-flex;
  flex-flow: column nowrap;

  background-color: white;
  box-shadow: 0px 0px 100px -10px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  width: 300px;
}
.travel-card:hover > .button-favorite {
  display: inline-flex;
}
.travel-card{
  
}
.travel-card .image {
  width: 100%;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  overflow: hidden;
  height: 157px;
}

 .image  img {
  width: 100%;

}
.travel-card > .content {
  color: #545454;
  padding: 1em 1.5em;
}
.travel-card > .content > :nth-child(1n+2) {
  margin-top: 1em;
}
.category {
  font-size: 1em;
  color: #bababa;
  position: absolute;
  display: inline-block;
  white-space: nowrap;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
}


.travel-card > .content > .recommendation {
  display: flex;
  align-items: center;
}
.travel-card > .content > .recommendation > .score {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #D0312D;
font-size:10px;
  color: white;
  padding: 5px 5px;

}
.score {
  padding-top: 1px;
  padding-left: 4px;
}

.travel-card > .content > .recommendation > .comment {
  color: #bababa;
  margin-left: 1.5em;
}
.travel-card > .content > .price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.discount-info {
  color: #4fc3f7;
}
.original-price {
  font-size: 1em;
  
}
.location-image {
  vertical-align: middle;
  margin-right: 5px;
}

.travel-card > .button-favorite {
  
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
}




:root {
  --v-50: #d8e6ea;
  --v-100: #9cbecb;
  --v-200: #6f9dac;
  --v-300: #42748e;
  --v-400: #1a4c6f;
  --v-700: #022D36; 
}

.breadcrumbs {
  width: 100%;
  list-style: none;
  display: flex;
  gap: 0.5rem;
  
  padding: 0.2rem;
  border-radius: 0.5rem;

  font-size: 10px;
}

.breadcrumbs-item {
  padding: 0.2rem;
  text-align: center;
  white-space: nowrap;
  background: var(--v-100);
  color: var(--v-700);
  text-decoration: none;
  text-transform: uppercase;
 width:100%;
  position: relative;
}

.breadcrumbs-item::before,
.breadcrumbs-item::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 0.5rem;
  top: 0;
  left: 0;
  clip-path: polygon(0 0, 100% 50%, 0 100%);
}

.breadcrumbs-item::before {
  background: var(--v-50)
}

.breadcrumbs-item::after {
  left: 100%;
  background: var(--v-100);
}

.breadcrumbs-item:first-child {
  padding: 0.2rem;
  border-radius: 0.25rem 0 0 0.25rem;
}

.breadcrumbs-item:first-child::before {
  display: none;
}

.breadcrumbs-item:last-child {
  border-radius: 0 0.25rem 0.25rem 0;
}

.breadcrumbs-item:last-child::after {
  display: none;
}

.breadcrumbs-item:hover,
.breadcrumbs-item:hover::after {
  
  color: var(--v-100);
}

@media only screen and (max-width: 768px) {
.question {
  margin-top:-100px;
}
.pricing {
  
  margin-top:-280px;
}
.about {
  
  margin-top: -180px;
}
}

/********* Login / signup ***********/

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
 
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  
}

.spinner {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  text-align: center;
  line-height: 40px; /* Adjust according to the height of the spinner */
  color: #3498db; /* Spinner color */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.auth {
  width: 40%;
  background-color: #fcf2f2;
  padding: 20px;
  border-radius: 8px;
  
}

.button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.button-container button {
    padding: 10px 20px;
    background-color: #022D36;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.button-container button:disabled {
    background-color: #A0A0A0;
    color: #FFF;
    cursor: not-allowed;
}
@media only screen and (max-width: 768px) {
  
  .auth{
  width: 90%;
  }
}
.boxz {
    
    border: 2px solid #000000; 
    padding: 20px;
    margin-bottom: 20px;
}



.body {
  background-color: #fbe3e1;
}

form p {
  font-size: 120%;
}

.ctrlqHeaderMast {
  height: 278px;
  background: #009688;
}

.ctrlqFormContent {
  color: rgba(0,0,0,.87);
  padding: 20px 35px
}

.ctrlqFormContentWrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  flex-direction: column
}

.ctrlqAccent {
  background-color: #a7ffeb;
  height: 8px;
}
.ctrlqCenteredContent {
  margin: auto;
  width: 600px; 
}
.ctrlqFormCard {
  background-color: #fff;
  margin-bottom: 48px;
  -webkit-box-shadow: 0 1px 4px 0 rgba(0,0,0,0.37);
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.37);
  word-wrap: break-word
}

.ctrlqFormCard:first-of-type {
  margin-top: -100px
}

.ctrlqHeaderTitle {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 34px;
  line-height: 135%;
  max-width: 100%;
  min-width: 0%;
  margin-bottom: 22px
}

@media (max-width: 660px) {
  .ctrlqHeaderMast {
    height: 122px;
  }
  .ctrlqFormCard:first-of-type {
    margin-top: -50px;
  }

  .ctrlqCenteredContent {
    width: 90%;
  }
}

div.error {
  position: relative;
  top: -1rem;
  left: 0rem;
  font-size: 0.8rem;
  color: #FF4081;
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%);
}

/* custom.css */
/* Remove bottom border on label when input is focused */
.input-field input[type="text"]:focus + label,
.input-field input[type="text"]:focus + label::after,
.input-field input[type="text"].valid + label,
.input-field input[type="text"].valid + label::after,
.input-field input[type="text"].invalid + label,
.input-field input[type="text"].invalid + label::after {
  text-decoration: none;
  border-bottom: none !important;
  box-shadow: none !important;
}
.feature-item {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 20px;
  padding: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;

  /* Responsive adjustments */
  width: 100%; /* Default to full width */
}

.feature-item:hover {
  transform: translateY(-5px);
}

.logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.feature-logo {
  max-width: 100%;
  height: auto;
}

.content {
  text-align: center;
}

.social-links {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.social-icon {
  width: 30px;
  height: 30px;
  fill: currentColor;
  transition: transform 0.2s ease-in-out;
}

.social-icon:hover {
  transform: scale(1.2);
}



.modal-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.container {
    max-width: 1320px;
    padding: 0 2rem;
    margin: 0 auto;
    height: 100%;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3%;
    margin: 5% 0;
}

.card-team {
    overflow: hidden;
    padding: 20px;
    background: linear-gradient(171.38deg, #F9FBFC 3.36%, #E2EAF5 95.69%);
    box-shadow: 0px 0px 20px rgba(227, 235, 246, 0.3);
    border-radius: 30px;
    text-align: center;
}
.card-team:hover {
  transform: translateY(-5px);
}

.card-team_img img {
  max-width: 100%;
  height: auto;
}

.card-team_body {
  text-align: center;
}

.designation {
  color: gray;
}

/* Center the modal on the page */
.mod {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  margin-top: 30px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5)!important;
  max-width: 80%!important;
  max-height: 70%!important;
  width: 500px!important;
  z-index: 1000;
  height: 90%!important;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mod-l {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 999; /* Make sure it is below the modal but above other content */
}
.mod-content {
  position: relative;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}

.close-btn {
  cursor: pointer;
  padding:2px;
  background-color: #eee;
  color: black;
  border: none;
  border-radius: 5px;
  float: left;
}

.close-btn:hover {
  background-color: #0056b3;
}

.paragraph {
  margin-bottom: 1rem;
}
.card-team_img img {
    width: 100%;
    height: auto;
    border-radius: 20px;
}

.card-team_body {
    padding: 26px 10px 36px 10px;
}

.card-team_title {
    font-weight: 800;
    text-transform: capitalize;
    color: #25262C;
    font-size: 20px!important!;
    padding-bottom: 15px;
}

.designation {
    font-weight: 700;
    font-size: 22px;
    color: #959EAF;
}

.call_btn {
    margin: 140px 0 40px;
    position: relative;
    display: inline-block;
    z-index: 99;
}




@media only screen and (max-width: 1024px) {

    .grid {
        grid-template-columns: 1fr;
        gap: 2%;
    }
}
.mb-2{
  
  color:white;
  padding: 10px;
  
}

@media only screen and (max-width: 425px) {
    .container {
        padding: 0 1rem;
    }

    .card-team {
        padding: 12px;
    }

    .card-team_title {
        font-size: 20px;
    }

    .designation {
        font-size: 18px;
    }

    .call_btn {
        margin: 100px 0 25px;
    }
}